table {
  border-collapse: separate !important;
  border-spacing: 0;
  width: 100%;
}

table td,
table th {
  padding: 8px;
}

table th,
tfoot td {
  padding-top: 12px;
  padding-bottom: 12px;
}

table th {
  background-color: #f8f9fb;
}

thead tr:first-child th:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

thead tr:first-child th:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.tab-link-not-active {
  transition: background 200ms ease;
  -webkit-transition: background 200ms ease;
}

.tab-link-not-active:hover {
  background: #97d0ee;
}

.transition-enter {
  opacity: 0;
}

.transition-enter-active {
  opacity: 1;
  transition: all 400ms ease-in;
  -webkit-transition: all 400ms ease-in;
}

.table-container {
  margin-top: 16px;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

.create-button {
  cursor: pointer;
}

.table-text {
  /* padding-left: 20px; */
  color: #000;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0px;
  text-indent: 0px;
  margin-right: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: stretch;
  font-size: 12px;
}

.custom-header {
  justify-content: stretch;
  align-content: stretch;
  flex: 0 0 auto;
  -webkit-box-align: center;
  align-items: center;
}

.table-header-checkbox {
  display: inline;
  vertical-align: middle;
}

.load-more-button {
  color: black !important;
}

.draggable-cell {
  width: 86px;
  height: 30px;
  padding-left: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e1e6f0;
}

.unclickable:hover {
  cursor: default !important;
}

.table-button-group {
  justify-self: end;
}

.disabled {
  cursor: not-allowed !important;
}

.disabled:hover {
  background-color: #b6c5d4 !important;
  color: #e1e6f0 !important;
}

.draggable-row-td {
  border-bottom: 1px solid #e1e6f0;
}

.draggable-row {
  cursor: default !important;
}

.rotate-180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.chevron-container {
  display: grid;
}

.chevron {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  margin-top: 10px;
  cursor: pointer;
}

.chevron:hover {
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
}

.chevron-disabled {
  cursor: not-allowed;
  margin-top: 10px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.delete-cell {
  justify-self: center;
  cursor: pointer;
  vertical-align: middle;
  min-width: 30px;
}

.delete-cell.del-disabled {
  cursor: not-allowed;
}

.replace-cell-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  justify-self: center;
}

.replace-cell {
  padding: 0px 15px 0px 0px !important;
  background-color: rgba(56, 152, 236, 0) !important;
  color: #000 !important;
}

.no-rows {
  -webkit-column-span: all;
  column-span: all;
  font-style: italic;
  justify-content: center;
  text-align: center;
  padding: 20px;
}
