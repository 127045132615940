.banner {
  position: fixed;
  width: 100%;
  padding: 20px;
  background-color: #000;
  top: 0;
  left: 0;
  padding-right: 40px;
  padding-left: 40px;
  z-index: 9998;
  /* height: 10vh; */
}

.text-banner {
  padding-top: 10px;
  color: #fff;
}

.change-banner-button {
  border-radius: 20px !important;
  background-color: #fff;
  color: #000 !important;
  margin-left: 15px;
  cursor: pointer;
}

.title-banner {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: center;
  -webkit-box-align: center;
}