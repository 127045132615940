.tab-link-not-active {
  transition: background 200ms ease;
  -webkit-transition: background 200ms ease;
}

.tab-link-not-active:hover {
  background: #97d0ee;
}

/* .tabbed-transition-enter {
  opacity: 0;
}

.tabbed-transition-enter-active {
  opacity: 1;
  transition: all 400ms ease-in;
  -webkit-transition: all 400ms ease-in;
}

.tabbed-transition-exit {
  opacity: 0;
}

.tabbed-transition-exit-active {
  opacity: 1;
  transition: all 400ms ease-in;
  -webkit-transition: all 400ms ease-in;
} */

.hidden {
  display: none;
}
