.close-button {
  background-color: #3898ec;
}

.confirm {
  padding: 30px !important;
}

.confirm-message {
  padding-top: 20px;
  padding-bottom: 20px;
}

.cancel-btn {
  margin-right: 8px;
  padding-right: 40px !important;
  padding-left: 40px !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-color: #627183 !important;
  background-color: transparent;
  color: #000 !important;
  text-align: center !important;
  border-radius: 60px !important;
}

.cancel-btn:hover {
  background-color: #627183 !important;
  color: white !important;
}

.button-6.delete:hover {
  background-color: #c10408 !important;
  color: #fff;
}

.button-6.delete {
  text-align: center;
}

.button-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}
