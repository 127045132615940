.header-button-container {
  display: grid;
}

.header-button {
  height: auto;
}

.border-block.header {
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
}

.status-2.header {
  justify-content: center !important;
}
