.nav-link-text:hover {
  color: #1d94d2;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
}

.logout.nav-link-text {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}

.rotate {
  -moz-transition: all 100ms linear;
  -webkit-transition: all 100ms linear;
  transition: all 100ms linear;
}

.rotate.down {
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.ease {
  -moz-transition: all 200ms linear;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  opacity: 0;
}

.ease.down {
  opacity: 1;
}

.nav-link.red {
  color: red;
}
