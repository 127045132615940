/* Set scrollbar so it does interfere with viewport width */
html {
  overflow-y: scroll;
}

/* Moved from webflow styles */
.sidebar-logo-wrapper {
  /* padding: 30px 24px 48px; */
  max-height: 80px;
}

.sidebar-logo {
  max-height: 40px;
  max-width: 200px;
}

/* Used on links where an <a> tag is unnecessary */
.link {
  text-decoration: none;
  cursor: pointer;
}

/* Extracted from webflow global styles, table-row-link-2 */
.table-row-link {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  -webkit-transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
}

.table-row-link:hover {
  box-shadow: 3px 3px 8px 8px rgba(118, 135, 154, 0.08), 0 1px 1px 0 rgba(118, 135, 154, 0.24);
  cursor: pointer;
}

/* Extracted from Global Webflow Styles */
.order_btn {
  border: 1px solid #000;
  border-radius: 5px;
  background-color: rgba(56, 152, 236, 0);
  line-height: 17px;
  margin-right: 8px;
  margin-left: 8px;
  margin-top: 8px;
}

.order_btn.back {
  border-style: solid;
  border-width: 1px;
  border-color: #9e9d9d;
  border-radius: 5px;
  color: #c9c9c9;
  line-height: 17px;
  cursor: not-allowed;
}

.order_btn.next {
  border-style: solid;
  border-width: 1px;
  border-color: #272727;
  color: #575555;
  cursor: pointer;
}

.order_btn.next:hover {
  background-color: #f8f9fb;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
}

.breadcrumb-text.active {
  pointer-events: none;
  color: gray;
  cursor: initial;
}

.breadcrumb-item + .breadcrumb-item::before {
  background-image: url("../images/chevron-right.svg");
  background-size: 15px 15px;
  background-position: left;
  display: inline-block;
  width: 15px;
  height: 15px;
  content: "";
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0;
}

.tabbed-transition-enter {
  opacity: 0;
}

.tabbed-transition-enter-active {
  opacity: 1;
  transition: all 400ms ease-in;
  -webkit-transition: all 400ms ease-in;
}

.tabbed-transition-exit {
  opacity: 0;
}

.tabbed-transition-exit-active {
  opacity: 1;
  transition: all 400ms ease-in;
  -webkit-transition: all 400ms ease-in;
}

.pad-app {
  padding-top: 10vh !important;
}

.custom-modal {
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  width: 40%;
  height: auto;
  padding: 40px;
  border-radius: 12px;
  background-color: #fff;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.modal-title {
  display: block;
  margin-top: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.form-block {
  margin-bottom: 0px;
  margin: 0 0 15px;
  height: auto;
  line-height: 24px;
}

.field-item {
  width: 100%;
}

.field-text {
  font-weight: 400;
}

.field-input {
  width: 100%;
  border-radius: 20px;
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #333333;
  background-color: #ffffff;
  border: 1px solid #cccccc;
}

.field-input:focus {
  border-color: #3898ec;
  outline: 0;
}

.field-input.small {
  width: 48% !important;
}

.button-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.form-button {
  margin-right: 0px;
  margin-left: 8px;
  padding: 5px 35px;
  border-radius: 20px;
  background-color: #1d94d2;
}

w-dropdown-toggle:disabled {
  cursor: not-allowed !important;
}

.field-input:disabled {
  cursor: not-allowed !important;
}

.cell-link:hover {
  color: #1d94d2;
  cursor: pointer;
}

.with-border {
  border-top: 1px solid #e1e6f0;
  padding-top: 20px;
}

.product__text {
  text-align: left;
}

.in-text-link {
  text-decoration: underline;
}

.in-text-link:hover {
  cursor: pointer;
  color: #1d94d2;
  text-decoration: none;
}

.material-icons.md-16 {
  font-size: 16px;
}

.orange {
  color: orange;
}
