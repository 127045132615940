.button.icon-button.back {
  /* background-color: #e1e6f0 !important; */
}

.button.icon-button.back:hover {
  background-color: transparent !important;
  color: #000;
}

.horizontal-wrapper {
  padding-bottom: 16px;
}
