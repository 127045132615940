.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.w-checkbox {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
}

.w-checkbox::before {
  content: " ";
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

.w-checkbox::after {
  content: " ";
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  clear: both;
}

.w-checkbox-input {
  float: left;
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: 0px;
  margin-top: 4px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
  background-image: url("https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-top-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-right-width: 4px;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
}

body {
  background-color: #f8f9fb;
  font-family: Inter, sans-serif;
  color: #000;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

h1 {
  margin-top: 0px;
  margin-bottom: 16px;
  font-size: 36px;
  line-height: 130%;
  font-weight: 700;
}

h2 {
  margin-top: 0px;
  margin-bottom: 12px;
  font-size: 26px;
  line-height: 130%;
  font-weight: 600;
}

h3 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 130%;
  font-weight: 600;
}

h4 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
}

h5 {
  margin-top: 0px;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

h6 {
  margin-top: 0px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
}

a {
  color: #000;
  font-weight: 400;
  text-decoration: none;
}

img {
  max-width: 100%;
}

blockquote {
  margin-bottom: 10px;
  padding: 10px 20px;
  border-left: 5px solid #b6c5d4;
  font-size: 18px;
  line-height: 22px;
}

.large-text {
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.01em;
}

.large-text.grey {
  color: #627183;
}

.large-text.margin-top {
  margin-top: 10px;
  line-height: 24px;
}

.style-guide-typography-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.display-heading-2 {
  margin-bottom: 24px;
  font-size: 54px;
  line-height: 56px;
  font-weight: 600;
}

.small-text {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 14px;
  line-height: 22px;
}

.small-text.text-grey-3 {
  color: #627183;
}

.container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 24px;
  padding-left: 24px;
}

.display-heading-1 {
  margin-top: 0px;
  margin-bottom: 24px;
  font-size: 72px;
  line-height: 72px;
  font-weight: 600;
  letter-spacing: -0.01em;
}

.style-guide-content-grid {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-auto-columns: 1fr;
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.no-space-bottom {
  margin-bottom: 0px;
  line-height: 24px;
}

.style-guide-padding {
  padding: 25px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 1px 1px 0 rgba(118, 135, 154, 0.24), 0 3px 8px 0 rgba(118, 135, 154, 0.08);
}

.content-width-small {
  width: 100%;
  max-width: 327px;
}

.content-width-medium {
  width: 100%;
  max-width: 400px;
}

.grid {
  grid-row-gap: 50px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.space-bottom {
  margin-bottom: 24px;
}

.space-top {
  margin-top: 24px;
}

._4-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

._3-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.bg-grey-2 {
  background-color: #627183;
}

.bg-grey-3 {
  background-color: #b6c5d4;
}

.bg-white {
  background-color: #fff;
}

.bg-white.style-guide-color.style-guide-color-white {
  border-style: solid;
  border-width: 1px;
  border-color: #ebebec;
}

.color-scheme-row {
  display: -ms-grid;
  display: grid;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.style-guide-color {
  min-height: 100px;
  margin-bottom: 8px;
  border-radius: 6px;
}

.icon-button-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.style-guide-buttons-grid {
  display: -ms-grid;
  display: grid;
  justify-items: center;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.button {
  display: inline-block;
  padding: 12px 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  background-color: #1d94d2;
  -webkit-transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
  color: #fff;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}

.button:hover {
  box-shadow: inset 100px 0 100px 100px rgba(0, 0, 0, 0.3);
  opacity: 1;
}

.button.button-large {
  padding: 18px 36px;
}

.button.button-small {
  padding: 8px 18px;
  font-size: 14px;
}

.button.icon-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 12px 24px;
}

.button.icon-button.back {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  background-color: transparent;
  color: #000;
}

.button.icon-button.back:hover {
  background-color: rgba(248, 249, 251, 0);
  box-shadow: none;
  color: #000;
}

.button.button-outline {
  border-style: solid;
  border-width: 1px;
  border-color: rgba(20, 20, 20, 0.1);
  background-color: transparent;
  color: #141414;
}

.button.button-outline:hover {
  box-shadow: inset 100px 0 100px 100px rgba(20, 20, 20, 0.1);
}

.button.cta {
  background-color: #fff;
  -webkit-transition: color 200ms ease, background-color 200ms ease, box-shadow 200ms ease;
  transition: color 200ms ease, background-color 200ms ease, box-shadow 200ms ease;
  color: #1d94d2;
}

.button.cta:hover {
  background-color: #1d94d2;
  box-shadow: none;
  color: #fff;
}

.button.button-spacing {
  margin-right: 16px;
}

.button.bg-white {
  background-color: #fff;
  -webkit-transition: color 200ms ease, background-color 200ms ease, box-shadow 200ms ease;
  transition: color 200ms ease, background-color 200ms ease, box-shadow 200ms ease;
  color: #1d94d2;
}

.button.bg-white:hover {
  box-shadow: none;
  color: #000;
}

.style-guide-form-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.radio-button-label {
  margin-bottom: 0px;
  margin-left: 5px;
  font-family: Inter, sans-serif;
  color: #627183;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}

.style-guide-grid-heading {
  margin-bottom: 16px;
}

.switch-input {
  width: 36px;
  height: 20px;
  margin-top: 0px;
  margin-right: 8px;
  margin-left: 0px;
  border-style: none;
  border-radius: 10px;
  background-color: #ebebec;
  background-image: url("../images/switch-handle-circle.svg");
  background-position: 5px 50%;
  background-size: 12px 12px;
  background-repeat: no-repeat;
  -webkit-transition: background-position 200ms ease, background-color 200ms ease;
  transition: background-position 200ms ease, background-color 200ms ease;
  cursor: pointer;
}

.switch-input.w--redirected-checked {
  border-style: none;
  background-color: #1d94d2;
  background-image: url("../images/switch-handle-circle.svg");
  background-position: 19px 50%;
  background-size: 12px 12px;
}

.switch-input.w--redirected-focus {
  box-shadow: 0 0 3px 1px #4253fe;
}

.checkbox-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  cursor: pointer;
}

.switch-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-left: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.form-block {
  margin-bottom: 0px;
}

.radio-button {
  width: 16px;
  height: 16px;
  margin-top: 0px;
  margin-left: 0px;
  border-color: #b6c5d4;
  background-color: #f8f9fb;
  -webkit-transition: border-color 200ms ease, border-width 200ms ease;
  transition: border-color 200ms ease, border-width 200ms ease;
  cursor: pointer;
}

.radio-button.w--redirected-checked {
  border-width: 5px;
  border-color: #1d94d2;
}

.radio-button.w--redirected-focus {
  box-shadow: 0 0 3px 1px #1d94d2;
}

.form-success {
  padding: 22px 18px 18px;
  border-radius: 12px;
  background-color: #1d94d2;
  color: #fff;
}

.form-error {
  margin-top: 12px;
  padding: 22px 18px 18px;
  border-radius: 12px;
  background-color: #dc0004;
  color: #fff;
  text-align: center;
}

.radio-button-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.checkbox-label {
  font-family: Inter, sans-serif;
  color: #000;
  font-size: 14px;
  font-weight: 400;
}

.checkbox-label.hidden {
  display: none;
}

.checkbox {
  display: block;
  width: 16px;
  height: 16px;
  margin-bottom: 8px;
  margin-left: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(20, 20, 20, 0.1);
  border-radius: 5px;
  background-image: url("../images/icon-check.svg");
  background-position: 100% 50%;
  background-size: 10px 10px;
  background-repeat: no-repeat;
  background-attachment: scroll;
  -webkit-transition: background-color 200ms ease, border-color 200ms ease,
    background-position 200ms ease;
  transition: background-color 200ms ease, border-color 200ms ease, background-position 200ms ease;
  cursor: pointer;
}

.checkbox.w--redirected-checked {
  border-color: #763ff9;
  background-color: #1d94d2;
  background-image: url("../images/icon-check.svg");
  background-size: 10px 10px;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.checkbox.w--redirected-focus {
  box-shadow: 0 0 3px 1px #4253fe;
}

.checkbox.text-right {
  margin-top: 0px;
  margin-right: 8px;
  margin-bottom: 0px;
}

.checkbox.no-space-bottom {
  margin-top: 10px;
  margin-bottom: 0px;
}

.style-guide-section {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f8f9fb;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.utility-page-icon {
  width: 200px;
  margin-right: auto;
  margin-bottom: 24px;
  margin-left: auto;
}

.text-field {
  padding: 20px 12px;
  border-style: solid;
  border-width: 1px;
  border-color: #e1e6f0;
  border-radius: 5px;
  background-color: #fff;
  font-size: 14px;
  line-height: 20px;
}

.text-field::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 20px;
}

.text-field:-ms-input-placeholder {
  font-size: 14px;
  line-height: 20px;
}

.text-field::-ms-input-placeholder {
  font-size: 14px;
  line-height: 20px;
}

.text-field::placeholder {
  font-size: 14px;
  line-height: 20px;
}

.rich-text {
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
}

.rich-text img {
  margin-top: 10px;
}

.rich-text a {
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: #1d94d2;
}

.rich-text a:hover {
  color: #492799;
}

.bg-primary-purple {
  background-color: #7a31e3;
}

.bg-primary-purple.style-guide-color {
  background-color: #1d94d2;
}

.bg-primary-yellow {
  background-color: #ffb731;
}

.bg-grey-1 {
  background-color: #000;
  color: #fff;
}

.bg-grey-1.style-guide-color {
  background-color: #000;
}

.bg-grey-4 {
  background-color: #b6c5d4;
}

.bg-grey-5 {
  background-color: #e1e6f0;
}

.bg-grey-6 {
  background-color: #f8f9fb;
}

.bg-primary-rose {
  background-color: #dc0004;
}

.bg-primary-rose.style-guide-color {
  background-color: #dc0004;
}

.bg-primary-orange {
  background-color: #ec7028;
}

.bg-primary-orange.style-guide-color {
  background-color: #ec7028;
}

.bg-primary-green {
  background-color: #00dc21;
}

.bg-primary-green.style-guide-color {
  background-color: #00dc21;
}

.bg-primary-blue {
  background-color: #1d94d2;
  color: #fff;
}

.bg-primary-blue.style-guide-color {
  background-color: #1d94d2;
}

._2-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

._2-grid.text-center {
  text-align: center;
}

._2-1-grid {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 2fr 1fr;
  grid-template-columns: 2fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

._1-2-grid {
  -ms-grid-columns: 1fr 2fr;
  grid-template-columns: 1fr 2fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.dashboard {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.dashboard-sidebar {
  position: fixed;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: transparent;
}

.sidebar-content {
  position: fixed;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  z-index: 500;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  width: 250px;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-right: 1px solid #e1e6f0;
  background-color: #fff;
}

.nav-link {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 3px 0px;
  padding: 10px 24px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-left: 2px solid transparent;
  opacity: 0.7;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  color: #1d94d2;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.nav-link.w--current {
  border-left: 2px solid #1d94d2;
  opacity: 1;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}

.sidebar-logo-wrapper {
  padding: 30px 24px 48px;
}

.sidebar-logo {
  max-height: 24px;
  max-width: 200px;
}

.nav-icon {
  width: 24px;
  margin-right: 20px;
}

.text-grey-3 {
  color: #627183;
}

.nav-divider {
  height: 0px;
  margin: 24px;
  border-style: solid;
  border-width: 1px;
  border-color: #f8f9fb;
  background-color: #e1e6f0;
}

.nav-divider.gray {
  outline-color: #000;
  outline-offset: 0px;
  outline-style: solid;
  outline-width: 3px;
}

.nav-link-dropdown {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 6px 0px;
  padding: 10px 24px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-left: 2px solid transparent;
  opacity: 0.7;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  color: #1d94d2;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.nav-link-dropdown.w--current {
  border-left: 2px solid #1d94d2;
  opacity: 1;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}

.dropdown {
  margin-right: 1px;
  margin-left: 0px;
}

.nav-dropdown-icon {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  margin-top: auto;
  margin-right: 24px;
  margin-bottom: auto;
}

.dropdown-list {
  background-color: #fff;
}

.dropdown-list.w--open {
  position: static;
  background-color: #fff;
}

.nav-link-dropdown-item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 6px 0px;
  padding: 10px 24px 10px 48px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-left: 2px solid transparent;
  opacity: 0.7;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  color: #1d94d2;
  font-size: 14px;
  line-height: 20px;
}

.nav-link-dropdown-item.w--current {
  border-left: 2px solid #1d94d2;
  opacity: 1;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}

.mobile-bar {
  position: fixed;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: 600;
  display: none;
  padding: 16px 24px;
  border-top: 1px solid #e1e6f0;
  background-color: #fff;
}

.mobile-bar-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.image {
  border-radius: 8px;
}

.lottie-menu-icon {
  width: 32px;
  height: 32px;
  margin-top: -3px;
}

.dashboard-content {
  overflow: hidden;
  max-width: 1200px;
  margin-left: 325px;
  padding: 0px 0px 30px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.caption {
  margin-bottom: 2px;
  color: #627183;
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.module {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1px;
  padding: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 1px 1px 0 rgba(118, 135, 154, 0.24), 0 3px 8px 0 rgba(118, 135, 154, 0.08);
}

.module.error {
  border-style: solid;
  border-width: 4px;
  border-color: #dc0004;
}

.module.pricing {
  position: relative;
  padding-top: 48px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  text-align: center;
}

.module.pricing.popular {
  background-color: #000;
  color: #fff;
}

.content-chart {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.chart-square {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 48px;
  height: 48px;
  margin-right: 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 16px;
}

.light-fill {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-color: #00dc21;
  opacity: 0.15;
}

.light-fill.bg-primary-green {
  background-color: #1d94d2;
}

.light-fill.bg-primary-rose {
  background-color: #dc0004;
}

.light-fill.bg-primary-orange {
  background-color: #ec7028;
}

.light-fill.bg-primary-purple {
  background-color: #7a31e3;
}

.light-fill.bg-primary-yellow {
  background-color: #ffb731;
}

.icon {
  z-index: 5;
}

.caption-large {
  color: #627183;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.div-block {
  width: 100%;
}

.numbers-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.number {
  margin-bottom: 0px;
}

.positive-value {
  margin-left: 4px;
  padding: 3px 6px;
  border-style: solid;
  border-width: 1.5px;
  border-color: rgba(1, 221, 165, 0.2);
  border-radius: 100px;
  color: #00dc21;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
}

.negative-value {
  margin-left: 4px;
  padding: 3px 6px;
  border-style: solid;
  border-width: 1.5px;
  border-color: rgba(255, 1, 116, 0.1);
  border-radius: 100px;
  color: #dc0004;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
}

.main-grid {
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.cirlce-chart {
  width: 100%;
  max-width: 220px;
  margin-bottom: 24px;
}

.child-content-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.chart-colors-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -10px;
  margin-left: -10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.indication-color {
  width: 10px;
  height: 10px;
  margin-right: 8px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 100px;
  background-color: #b6c5d4;
}

.indication-color.bg-primary-green {
  background-color: #b6c5d4;
}

.indication-color.bg-primary-blue {
  background-color: #1d94d2;
}

.indication-color.bg-primary-rose {
  background-color: #dc0004;
}

.indication-color.bg-primary-green {
  background-color: #01dda5;
}

.indication-color.bg-primary-green.dropdown {
  background-color: #1d94d2;
}

.indication-color.bg-primary-yellow {
  background-color: #ffb731;
}

.indication-color.bg-primary-black {
  background-color: #dc0004;
}

.label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
  margin-left: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.card-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-bottom: 1px solid #e1e6f0;
}

.charts-preview {
  width: 100%;
  max-height: 260px;
}

.text-grey-2 {
  color: #25364e;
}

.header-detail {
  color: #627183;
  font-size: 14px;
}

.profile-banner {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 250px;
  margin-bottom: 24px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border-radius: 8px;
  background-image: url("../images/RealtorShop_Web_Logos_ALL_With_Padding_RealtorShop_Colour-01.png"),
    linear-gradient(180deg, #f4f4f4, #f4f4f4);
  background-position: center;
  background-size: contain, auto;
  background-attachment: scroll, scroll;
  -o-object-fit: cover;
  object-fit: cover;
}

.profile-picture {
  width: 100px;
  height: 100px;
  margin-right: 20px;
  border-style: solid;
  border-width: 3px;
  border-color: #fff;
  border-radius: 10px;
}

.profile-avatar {
  position: relative;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
  margin-left: 24px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
}

.fade-out {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 60%;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(0, 0, 0, 0.35)),
    color-stop(35%, rgba(0, 0, 0, 0.3)),
    to(transparent)
  );
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.3) 35%, transparent);
}

.text-white {
  color: #fff;
}

.profile-details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #e1e6f0;
}

.profile-details.bottom {
  border-bottom-style: none;
}

.weight-500 {
  font-weight: 500;
}

.stacked-modules {
  -webkit-align-content: start;
  -ms-flex-line-pack: start;
  align-content: start;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.user-details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.user-avatar {
  width: 48px;
  height: 48px;
  margin-right: 8px;
  border-radius: 100px;
  -o-object-fit: cover;
  object-fit: cover;
}

.feed-interaction-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 16px;
  padding-bottom: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.feed-interactions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 24px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0.9;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: opacity 200ms ease, -webkit-filter 200ms ease;
  transition: opacity 200ms ease, -webkit-filter 200ms ease;
  transition: filter 200ms ease, opacity 200ms ease;
  transition: filter 200ms ease, opacity 200ms ease, -webkit-filter 200ms ease;
}

.feed-interactions:hover {
  opacity: 1;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}

.feed-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.feed-interactions-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.feed-share {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0.9;
}

.in-page-menu {
  display: block;
  margin-bottom: 20px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.in-page-menu-link {
  margin-right: 12px;
  padding: 4px 24px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-style: solid;
  border-width: 1px;
  border-color: #e1e6f0;
  border-radius: 100px;
  background-color: transparent;
  color: #627183;
}

.in-page-menu-link.w--current {
  border-color: #1d94d2;
  background-color: #1d94d2;
  color: #fff;
}

.project-module {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 1px 1px 0 rgba(118, 135, 154, 0.24), 0 3px 8px 0 rgba(118, 135, 154, 0.08);
}

.project-thumbnail {
  width: 100%;
  height: 220px;
  border-radius: 5px;
  -o-object-fit: cover;
  object-fit: cover;
}

.project-module-content {
  padding: 16px 24px 24px;
}

.divider {
  width: 100%;
  height: 1px;
  margin-top: 16px;
  margin-bottom: 16px;
  background-color: #e1e6f0;
}

.divider._40-px {
  margin-top: 40px;
  margin-bottom: 40px;
}

.space-title-small {
  margin-bottom: 4px;
}

.project-avatar {
  width: 40px;
  height: 40px;
  margin-left: -14px;
  border-style: solid;
  border-width: 3px;
  border-color: #fff;
  border-radius: 100px;
}

.project-avatar.first {
  margin-left: 0px;
}

.project-details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.space-bottom-small {
  margin-bottom: 16px;
}

.project-module-top {
  padding: 10px;
}

.tabs-content {
  overflow: visible;
}

.alert-module {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 24px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 6px;
  background-color: #dc0004;
  box-shadow: 0 1px 1px 0 rgba(118, 135, 154, 0.24), 0 3px 8px 0 rgba(118, 135, 154, 0.08);
  color: #fff;
}

.table-module {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 1px 1px 0 rgba(118, 135, 154, 0.24), 0 3px 8px 0 rgba(118, 135, 154, 0.08);
}

.table-module.archived {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.table-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 24px 36px 16px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  line-height: 16px;
}

.table-row {
  padding: 16px 24px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  font-size: 14px;
}

.table-row._5-columns {
  -ms-grid-columns: 1fr 3fr 3fr;
  grid-template-columns: 1fr 3fr 3fr;
}

.table-headers {
  padding: 16px 24px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 8px;
  background-color: #f8f9fb;
}

.table-headers._5-columns {
  -ms-grid-columns: 1fr 3fr 3fr;
  grid-template-columns: 1fr 3fr 3fr;
}

.status {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.in-row-avatar {
  width: 40px;
  height: 40px;
  margin-left: -14px;
  border-style: solid;
  border-width: 3px;
  border-color: #fff;
  border-radius: 100px;
}

.in-row-avatar.first {
  margin-left: 0px;
}

.table-avatar-row {
  margin-top: -8px;
  margin-bottom: -8px;
}

.mobile-bottom-spacing {
  height: 0px;
}

.table-title {
  font-weight: 500;
}

.table-bottom-caption {
  padding: 16px 36px;
  color: #627183;
  font-size: 14px;
}

.kanban {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  min-height: 70vh;
  padding-bottom: 24px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.kanban-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 300px;
  margin-right: 24px;
  padding: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 1px 1px 0 rgba(118, 135, 154, 0.24), 0 3px 8px 0 rgba(118, 135, 154, 0.08);
}

.kanban-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
  padding: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  border-color: #e1e6f0;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 3px 8px 0 rgba(118, 135, 154, 0.08);
}

.kanban-image {
  margin-bottom: 8px;
  border-radius: 8px;
}

.kanban-avatar {
  width: 30px;
  height: 30px;
  margin-left: -14px;
  border-style: solid;
  border-width: 3px;
  border-color: #fff;
  border-radius: 100px;
}

.kanban-avatar.first {
  margin-left: 0px;
}

.kanban-details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 8px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #627183;
}

.kanban-link {
  margin-top: 8px;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: #1d94d2;
  font-weight: 400;
  text-align: center;
}

.kanban-link:hover {
  color: #492799;
}

.content-storage {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.storage-square {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 48px;
  height: 48px;
  margin-bottom: 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 16px;
}

.percentage-bar-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 6px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: #e1e6f0;
}

.percentage-bar {
  width: 60%;
  height: 5px;
  border-radius: 100px;
}

.percentage-bar.bg-primary-blue {
  height: 100%;
}

.small-description {
  color: #627183;
  font-size: 14px;
  line-height: 20px;
}

.percentage-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 16px;
  margin-bottom: 4px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.content-percentage {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.data-visualisation-storage {
  height: 120px;
  margin-left: 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.folder-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.storage-folder-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.module-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 1px 1px 0 rgba(118, 135, 154, 0.24), 0 3px 8px 0 rgba(118, 135, 154, 0.08);
  -webkit-transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
}

.module-link:hover {
  box-shadow: 0 2px 1px 0 rgba(118, 135, 154, 0.24), 0 10px 20px 0 rgba(118, 135, 154, 0.1);
}

.content-folder {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.main-grid-title {
  margin-top: 16px;
  margin-bottom: -4px;
}

.kanban-color-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 4px;
  margin-bottom: 16px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: #e1e6f0;
}

.kanban-color-bar.in-progress {
  background-color: #ffb731;
}

.kanban-color-bar.done {
  background-color: #00dc21;
}

.kanban-color-bar.next-up {
  background-color: #1d94d2;
}

.file-square {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 40px;
  height: 40px;
  margin-right: 8px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 8px;
}

.file-icon {
  z-index: 5;
  height: 20px;
}

.file-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.team-picture {
  width: 100px;
  height: 100px;
  margin-right: 20px;
  border-style: solid;
  border-width: 3px;
  border-color: #fff;
  border-radius: 10px;
}

.team-banner {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 250px;
  margin-bottom: 24px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border-radius: 8px;
  background-image: url("../images/Banner-Team-min.jpg");
  background-position: 50% 50%;
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}

.members-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #e1e6f0;
}

.members-list.first {
  padding-top: 0px;
}

.members-list.last {
  padding-bottom: 0px;
  border-bottom-style: none;
}

.member-list-avatar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-primary-blue {
  color: #1d94d2;
}

.projects-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #e1e6f0;
}

.projects-list.first {
  padding-top: 0px;
}

.projects-list.last {
  padding-bottom: 0px;
  border-bottom-style: none;
}

.project-list-logo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.project-small-thumbnail {
  width: 64px;
  height: 48px;
  margin-right: 8px;
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 rgba(118, 135, 154, 0.15);
  -o-object-fit: cover;
  object-fit: cover;
}

.members-overview-list-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.members-overview-avatar {
  width: 64px;
  height: 64px;
  margin-right: 16px;
  border-radius: 100px;
  -o-object-fit: cover;
  object-fit: cover;
}

.table-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 16px;
  padding-left: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.account {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 48px;
  padding-top: 24px;
  padding-bottom: 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #e1e6f0;
}

.form-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 14px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.form-caption {
  margin-bottom: 8px;
  color: #627183;
  font-size: 14px;
  line-height: 20px;
}

.account-divider {
  width: 100%;
  height: 1px;
  margin-top: 48px;
  margin-bottom: 48px;
  background-color: #e1e6f0;
}

.account-delete {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 48px;
  padding-top: 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #e1e6f0;
}

.billing-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.billing-storage-anaytic {
  height: 38px;
}

.full-table-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.full-table-row-detail {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.payment-method-image {
  width: 64px;
  height: 40px;
  margin-right: 16px;
  border-radius: 5px;
  -o-object-fit: cover;
  object-fit: cover;
}

.full-table-row-divider {
  height: 1px;
  margin-right: 24px;
  margin-left: 24px;
  background-color: #e1e6f0;
}

.text-button-combo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.password-reset-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 14px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.form-wrap {
  margin-bottom: 0px;
}

.device-image {
  height: 24px;
  margin-right: 16px;
  border-radius: 5px;
  -o-object-fit: fill;
  object-fit: fill;
}

.utility-container {
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
}

.invoice {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 14px;
  line-height: 22px;
}

.invoice-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 48px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-align: center;
}

.invoice-logo {
  max-height: 30px;
  margin-bottom: 24px;
}

.invoice-contacts {
  display: -ms-grid;
  display: grid;
  margin-bottom: 48px;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.text-right-invoice {
  text-align: right;
}

.invoice-contact-details {
  margin-bottom: 48px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #627183;
  font-size: 14px;
  line-height: 22px;
}

.table-headers-invoice {
  padding: 16px 24px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-areas: ". . Area";
  -ms-grid-columns: 1fr 0px 0.4fr 0px 0.4fr;
  grid-template-columns: 1fr 0.4fr 0.4fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 8px;
  background-color: #f8f9fb;
}

.table-row-invoice {
  padding: 16px 24px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 0.4fr 0.4fr;
  grid-template-columns: 1fr 0.4fr 0.4fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-bottom: 1px solid #e1e6f0;
  font-size: 14px;
}

.invoice-total {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.invoice-footer {
  margin-top: 48px;
  padding-top: 24px;
  border-top: 1px solid #e1e6f0;
  color: #627183;
}

.dashboard-container {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

.crm-company-logo {
  width: 30px;
  height: 30px;
  margin-right: 12px;
  -o-object-fit: contain;
  object-fit: contain;
}

.crm-name-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.crm-avatar {
  width: 30px;
  height: 30px;
  margin-right: 12px;
  border-radius: 100px;
  -o-object-fit: contain;
  object-fit: contain;
}

.pricing-list-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #e1e6f0;
  font-size: 14px;
  line-height: 22px;
}

.pricing-list-item.last {
  border-bottom-style: none;
}

.pricing-list-item.dark-border {
  border-bottom-color: #25364e;
}

.pricing-icon {
  height: 20px;
}

.pricing-list {
  margin-bottom: 24px;
}

.pricing-caption {
  margin-bottom: 16px;
  color: #627183;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.text-grey-4 {
  color: #b6c5d4;
}

.authentication-page {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 24px;
  padding-left: 24px;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #1d94d2;
  color: #fff;
}

.authentication-module {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 25vw;
  min-width: 300px;
  max-width: 350px;
  padding: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 1px 1px 0 rgba(118, 135, 154, 0.24), 0 3px 8px 0 rgba(118, 135, 154, 0.08);
  color: #000;
  text-align: center;
}

.authentication-field {
  margin-bottom: 0px;
  padding: 20px 12px;
  border-style: solid;
  border-width: 1px;
  border-color: #e1e6f0;
  border-radius: 5px;
  background-color: #f8f9fb;
  font-size: 14px;
  line-height: 20px;
}

.authentication-field::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 20px;
}

.authentication-field:-ms-input-placeholder {
  font-size: 14px;
  line-height: 20px;
}

.authentication-field::-ms-input-placeholder {
  font-size: 14px;
  line-height: 20px;
}

.authentication-field::placeholder {
  font-size: 14px;
  line-height: 20px;
}

.authentication-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.authentication-form-block {
  margin-bottom: 0px;
}

.authentication-grid {
  grid-row-gap: 8px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  text-align: center;
}

.remember-me-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-left: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.knowledge-base-link {
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e1e6f0;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: #1d94d2;
}

.knowledge-base-link:last-child {
  padding-bottom: 0px;
  border-bottom-style: none;
}

.knowledge-base-link:hover {
  color: #492799;
}

.knowledge-base-contact {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 48px;
  padding-top: 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #e1e6f0;
}

.article {
  max-width: 650px;
  margin-right: auto;
  margin-left: auto;
}

.article img {
  margin-top: 16px;
  margin-bottom: 16px;
}

.article a {
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: #1d94d2;
}

.article a:hover {
  color: #492799;
}

.back-to-home {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 24px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
}

.back-to-home.text-primary-blue {
  color: #1d94d2;
}

.back-icon {
  margin-right: 8px;
}

.small-link {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  color: #1d94d2;
  font-size: 14px;
  line-height: 22px;
}

.small-link:hover {
  color: #492799;
}

.alert-module-link {
  margin-left: 48px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.body-link {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  color: #1d94d2;
}

.body-link:hover {
  color: #492799;
}

.large-link {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  color: #1d94d2;
  font-size: 18px;
  line-height: 30px;
}

.large-link:hover {
  color: #492799;
}

.presentation-hero-section {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 120px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  text-align: center;
}

.presentation-title-wrapper {
  max-width: 800px;
  margin-right: auto;
  margin-bottom: 72px;
  margin-left: auto;
}

.dashboard-mockup {
  width: 100%;
  max-width: 1000px;
}

.presentation-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 96px;
  padding-bottom: 96px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.presentation-section.cta {
  background-color: #25364e;
  background-image: url("../images/Dash-Footer-Visual.jpeg");
  background-position: 100% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  color: #fff;
}

.presentation-hero-bg {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: -1;
  width: 100%;
  height: 740px;
  background-color: #000;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#041427), to(#102842));
  background-image: linear-gradient(180deg, #041427, #102842);
}

.presentation-module {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 1px 1px 0 rgba(118, 135, 154, 0.24), 0 3px 8px 0 rgba(118, 135, 154, 0.08);
  -webkit-transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
}

.presentation-module:hover {
  box-shadow: 0 1px 1px 0 rgba(118, 135, 154, 0.24), 0 8px 15px 0 rgba(118, 135, 154, 0.2);
}

.presentation-screen {
  border-radius: 5px;
  box-shadow: 0 15px 15px -15px rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.1);
}

.presentation-module-bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding: 16px 24px 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.presentation-module-top {
  padding: 10px;
}

.presentation-grid {
  display: -ms-grid;
  display: grid;
  max-width: 1000px;
  margin-right: auto;
  margin-bottom: 72px;
  margin-left: auto;
  grid-auto-columns: 1fr;
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.presentation-grid._3-column {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.presentation-grid.no-space-bottom {
  margin-bottom: 0px;
}

.presentation-category-wrapper {
  max-width: 600px;
  margin-right: auto;
  margin-bottom: 64px;
  margin-left: auto;
}

.style-guide-grid {
  grid-column-gap: 24px;
  grid-row-gap: 64px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
}

.presentation-cta {
  max-width: 520px;
  margin-right: auto;
  margin-left: auto;
}

.footer {
  margin-top: 24px;
  color: #b6c5d4;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  text-align: center;
}

.footer-link {
  color: #b6c5d4;
  font-weight: 500;
}

.presentation-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.logo-presentation {
  height: 36px;
  margin-right: auto;
  margin-bottom: 36px;
  margin-left: auto;
}

.no-margin {
  margin-top: 0px;
  margin-bottom: 0px;
}

.contact-avatar {
  width: 72px;
  height: 72px;
  margin-right: 24px;
  border-radius: 24px;
  -o-object-fit: cover;
  object-fit: cover;
}

.contact-description {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.company-logo {
  width: 64px;
  height: 64px;
  margin-right: 24px;
  -o-object-fit: cover;
  object-fit: cover;
}

.company-avatar {
  width: 72px;
  height: 72px;
  margin-right: 24px;
  -o-object-fit: cover;
  object-fit: cover;
}

.breadcrumb-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.banner-avatar-name {
  margin-bottom: 0px;
  line-height: 20px;
}

.banner-avatar-caption {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #627183;
  font-size: 14px;
  line-height: 22px;
}

.breadcrumb-text {
  color: #00162f;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
}

.breadcrumb-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 4px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.banner-avatar-image {
  width: 42px;
  height: 42px;
  margin-right: 8px;
  border-radius: 16px;
  -o-object-fit: cover;
  object-fit: cover;
}

.banner-avatar-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.breacrumb-chevron {
  width: 18px;
  margin-left: 4px;
}

.dashboard-banner-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.dashboard-banner-header.utility {
  max-width: 800px;
}

.dashboard-banner {
  overflow: hidden;
  margin-bottom: 0px;
  margin-left: 235px;
  padding: 30px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-bottom: 1px solid #e1e6f0;
  background-color: #fff;
}

.account-table-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 24px 24px 16px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.billing-subscription-title {
  margin-bottom: 0px;
}

.table-header-smaller-padding {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 24px 24px 16px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.text-block-15 {
  margin-right: 20px;
}

.text-block-15.product__text {
  display: block;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  color: #00dc21;
}

.text-block-15.product__text.red {
  color: #dc0004;
}

.text-block-15.product__text {
  margin-right: 0px;
  color: #000;
  text-align: center;
}

.text-block-15._16px {
  margin-right: 0px;
  font-size: 16px;
  font-weight: 500;
}

.text-block-15._16px.link {
  margin-right: 0px;
  color: #1d94d2;
  text-decoration: underline;
}

.icon-6 {
  margin-bottom: 14px;
}

.dropdown-toggle-4 {
  padding-top: 30px;
  padding-bottom: 10px;
}

.search-input {
  width: 30px;
  height: 48px;
  min-width: 400px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #e1e6f0;
  /* border-radius: 5px; */
  font-size: 15px;
}

.table-title-2.product__text {
  text-align: left;
}

.table-title-2.unbold {
  font-weight: 400;
}

.table-title-2.conform-action {
  text-align: left;
}

.table-content-2 {
  padding-top: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.product__text {
  color: #000;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0px;
  text-indent: 0px;
}

.product__text.unbold {
  font-weight: 400;
}

.div-block-27 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-style: solid;
  border-width: 2px;
  border-color: #b6c5d4;
  border-radius: 6px;
}

.dropdown-toggle-5 {
  padding-top: 30px;
  padding-bottom: 10px;
}

.dropdown-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}

.status-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: center;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  /* text-align: left; */
}

.status-2.header {
  margin-right: 20px;
  padding: 10px 20px;
  border-style: none;
  border-width: 1px;
  border-color: #e1e6f0;
  background-color: #f8f9fb;
}

.status-2.header.total {
  margin-right: 0px;
}

.table-module-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 1px 1px 0 rgba(118, 135, 154, 0.24), 0 3px 8px 0 rgba(118, 135, 154, 0.08);
}

.table-module-2._16px-margin {
  margin-bottom: 16px;
}

.searchbar {
  display: block;
  width: 100%;
  margin-top: 0px;
}

.searchbar.width {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.table-header-2 {
  display: block;
  padding: 24px 36px 16px 10px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.table-header-2.orderspage {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.table-header-2.orderspage.full-width {
  width: 100%;
  padding: 30px 28px;
}

.table-header-2.orderspage.full-width.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 16px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-2 {
  margin-top: 24px;
  color: #b6c5d4;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  text-align: center;
}

.dropdown-list-6 {
  display: none;
  overflow: visible;
  box-shadow: 1px 0 12px 0 rgba(9, 41, 58, 0.09);
}

.dropdown-list-6.w--open {
  background-color: transparent;
  box-shadow: 1px 0 12px 0 rgba(9, 41, 58, 0.2);
}

.searchbar_filters {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.searchbar_filters.align-lright {
  margin-top: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.searchbar_filters.align-lright.flex__space--betwen {
  margin-top: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.customer__search {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.dropdown-link {
  border-bottom: 1px solid #b6c5d4;
  background-color: #fff;
}

.table-bottom-caption-2 {
  display: block;
  padding: 16px 36px;
  border-style: solid none none;
  border-width: 1px;
  border-color: #e1e6f0 #494747 #494747;
  color: #627183;
  font-size: 14px;
  text-align: center;
}

.dropdown-link-2 {
  background-color: #fff;
}

.button-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  padding: 10px 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #1d94d2;
  color: #fff;
  font-weight: 500;
  text-align: center;
}

.button-2:hover {
  box-shadow: inset 100px 0 100px 100px rgba(0, 0, 0, 0.3);
  opacity: 1;
}

.button-2.not-center {
  margin-right: 0px;
  margin-left: 20px;
  border-radius: 40px;
  background-color: #1d94d2;
  font-size: 15px;
}

.button-2.not-center.fixed-height {
  position: relative;
  bottom: 0px;
  display: none;
  height: 48px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border-radius: 50px;
  text-align: center;
}

.button-2.not-center.disabled {
  background-color: #b6c5d4;
  color: #e1e6f0;
}

.button-2.not-center.disabled:hover {
  background-color: #1d94d2;
  box-shadow: none;
  color: #fff;
}

.button-2.not-center.disabled:active {
  background-color: #1d94d2;
  color: #fff;
}

.button-2.size__btn {
  display: block;
  margin-right: 0px;
  margin-left: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 50px;
  background-color: #1d94d2;
  font-size: 14px;
  text-transform: capitalize;
}

.button-2.size__btn.large {
  width: 148px;
  margin-left: 4px;
  text-transform: capitalize;
}

.button-2.size__btn._40px-padding {
  padding-right: 40px;
  padding-left: 40px;
}

.button-2.size__btn._8px {
  margin-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-transform: capitalize;
}

.button-2.analytics {
  border-radius: 40px;
  background-color: #1d94d2;
  font-size: 15px;
}

.button-2.analytics.fixed-height {
  position: relative;
  bottom: 0px;
  display: none;
  height: 48px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border-radius: 50px;
  text-align: center;
}

.button-2.analytics.disabled {
  background-color: #b6c5d4;
  color: #e1e6f0;
}

.button-2.analytics.disabled:hover {
  background-color: #1d94d2;
  box-shadow: none;
  color: #fff;
}

.button-2.analytics.disabled:active {
  background-color: #1d94d2;
  color: #fff;
}

.dashboard-content-2 {
  display: block;
  overflow: hidden;
  margin-left: 250px;
  padding: 30px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.caption-large-2 {
  color: #627183;
  font-size: 11px;
  line-height: 10px;
  font-weight: 700;
  /* text-align: center; */
  letter-spacing: 1px;
  text-transform: uppercase;
}

.footer-link-2 {
  color: #b6c5d4;
  font-weight: 500;
}

.table-list-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  padding-right: 8px;
  padding-left: 8px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.table-list-2.space-top.no-margin {
  padding-right: 0px;
  padding-left: 0px;
}

.table-list-2._40px-margin {
  margin-bottom: 40px;
}

.collection__title--wrraper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.collection__title--wrraper.space-between {
  margin-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.table-headers-2 {
  padding: 16px 24px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  /* border-radius: 8px;
  background-color: #f8f9fb; */
}

.table-headers-2._6-columns {
  -webkit-box-pack: stretch;
  -webkit-justify-content: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -ms-grid-row-align: auto;
  align-self: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  grid-column-gap: 4px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-eight {
  padding-right: 20px;
  padding-left: 20px;
  justify-items: start;
  -ms-grid-columns: 0.75fr 1fr 1fr 1fr 0.75fr 1fr 0.75fr;
  grid-template-columns: 0.75fr 1fr 1fr 1fr 0.75fr 1fr 0.75fr;
}

.table-headers-2._6-columns.with-checkbox.column-eight.products {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-eight.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-eight.collections {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-eight.add-products {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-eight.add-attributes {
  -ms-grid-columns: 1.5fr 1fr 1fr;
  grid-template-columns: 1.5fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-three {
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-three.products {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-three.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-three.collections {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-three.ratio {
  -ms-grid-columns: 1fr 1fr 0.5fr;
  grid-template-columns: 1fr 1fr 0.5fr;
}

.table-headers-2._6-columns.with-checkbox.column-three {
  padding-right: 20px;
  padding-left: 20px;
  justify-items: start;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-three.products {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-three.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-three.collections {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-six {
  padding-right: 20px;
  padding-left: 20px;
  justify-items: start;
  -ms-grid-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.75fr;
  grid-template-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.75fr;
}

.table-headers-2._6-columns.with-checkbox.column-six.products {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-six.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-six.collections {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-four {
  padding-right: 20px;
  padding-left: 20px;
  justify-items: start;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-four.products {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-four.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-four.collections {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-two {
  padding-right: 40px;
  padding-left: 40px;
  justify-items: start;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-two.products {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-two.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-two.collections {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-two.add-products {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-two.add-attributes {
  -ms-grid-columns: 1.5fr 1fr 1fr;
  grid-template-columns: 1.5fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-seven {
  padding-right: 20px;
  padding-left: 20px;
  justify-items: start;
  -ms-grid-columns: 0.5fr 0.75fr 0.75fr 0.75fr 0.5fr 0.5fr 0.75fr;
  grid-template-columns: 0.5fr 0.75fr 0.75fr 0.75fr 0.5fr 0.5fr 0.75fr;
}

.table-headers-2._6-columns.with-checkbox.column-seven.products {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-seven.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-seven.collections {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-seven.add-products {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.table-headers-2._6-columns.with-checkbox.column-seven.add-attributes {
  -ms-grid-columns: 1.5fr 1fr 1fr;
  grid-template-columns: 1.5fr 1fr 1fr;
}

.table-headers-2._5-column {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.table-headers-2._4-column {
  -ms-grid-columns: 1fr 1fr 1fr 0.75fr;
  grid-template-columns: 1fr 1fr 1fr 0.75fr;
}

.checkbox-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 5px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.checkbox-block.checkbox-block--pading-none {
  padding-left: 0px;
}

.checkbox-block.checkbox-block--pading-none.checkbox-block--title {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.checkbox-block.checkbox-block--pading-none.checkbox-block--title.hidden {
  display: none;
}

.dropdown-list-2 {
  display: none;
  margin-top: 60px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  background-color: #fff;
  box-shadow: 0 3px 8px 0 rgba(118, 135, 154, 0.08), 1px 1px 1px 0 rgba(118, 135, 154, 0.24);
}

.dropdown-list-2.w--open {
  display: none;
  margin-top: 0px;
}

.alert-module-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 24px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 6px;
  background-color: #1d94d2;
  box-shadow: 0 1px 1px 0 rgba(118, 135, 154, 0.24), 0 3px 8px 0 rgba(118, 135, 154, 0.08);
  color: #fff;
}

.no-space-bottom-2 {
  display: block;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-left: 0px;
  font-size: 26px;
  line-height: 32px;
  text-transform: none;
}

.no-space-bottom-2._40-px-padding {
  margin-bottom: 40px;
}

.add-prod-table-row {
  padding: 16px 24px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  /* -ms-grid-columns: 1fr 1fr 1fr 1fr; */
  /* grid-template-columns: 1fr 1fr 1fr 1fr; */
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  /* font-size: 14px; */
}

.add-prod-table-row._5-columns {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb {
  padding-right: 20px;
  padding-left: 20px;
  -ms-grid-columns: 0.75fr 1fr 1fr 1fr 0.75fr 1fr 0.75fr;
  grid-template-columns: 0.75fr 1fr 1fr 1fr 0.75fr 1fr 0.75fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text {
  -ms-grid-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-eight {
  padding-right: 20px;
  padding-left: 20px;
  -ms-grid-columns: 0.75fr 1fr 1fr 1fr 0.75fr 1fr 0.75fr;
  grid-template-columns: 0.75fr 1fr 1fr 1fr 0.75fr 1fr 0.75fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-eight.products {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-eight.products.even {
  border-radius: 10px;
  background-color: #f8f9fb;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-eight.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-4 {
  padding-right: 20px;
  padding-left: 20px;
  -ms-grid-columns: 0.75fr 1fr 1.25fr 1fr 0.75fr 1fr 0.5fr;
  grid-template-columns: 0.75fr 1fr 1.25fr 1fr 0.75fr 1fr 0.5fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-4.products {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-4.products.even {
  border-radius: 10px;
  background-color: #f8f9fb;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-4.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-four {
  padding-right: 20px;
  padding-left: 20px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-four.products {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-four.products.even {
  border-radius: 10px;
  background-color: #f8f9fb;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-four.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-three {
  padding-right: 20px;
  padding-left: 20px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-three.products {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-three.products.even {
  border-radius: 10px;
  background-color: #f8f9fb;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-three.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-three {
  padding-right: 20px;
  padding-left: 20px;
  -ms-grid-columns: 0.75fr 1fr 1.25fr 1fr 0.75fr 1fr 0.5fr;
  grid-template-columns: 0.75fr 1fr 1.25fr 1fr 0.75fr 1fr 0.5fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-three.products {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-three.products.even {
  border-radius: 10px;
  background-color: #f8f9fb;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-three.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-three {
  padding-right: 20px;
  padding-left: 20px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-three.products {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-three.products.even {
  border-radius: 10px;
  background-color: #f8f9fb;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-three.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-six {
  padding-right: 20px;
  padding-left: 20px;
  -ms-grid-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.75fr;
  grid-template-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.75fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-six.products {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-six.products.even {
  border-radius: 10px;
  background-color: #f8f9fb;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-six.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-four {
  padding-right: 20px;
  padding-left: 20px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-four.products {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-four.products.even {
  border-radius: 10px;
  background-color: #f8f9fb;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-four.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-three {
  padding-right: 20px;
  padding-left: 20px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-three.products {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-three.products.even {
  border-radius: 10px;
  background-color: #f8f9fb;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-three.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-two {
  padding-right: 40px;
  padding-left: 40px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-two.products {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-two.products.even {
  border-radius: 10px;
  background-color: #f8f9fb;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-two.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-seven {
  padding-right: 20px;
  padding-left: 20px;
  -ms-grid-columns: 0.5fr 0.75fr 0.75fr 0.75fr 0.5fr 0.5fr 0.75fr;
  grid-template-columns: 0.5fr 0.75fr 0.75fr 0.75fr 0.5fr 0.5fr 0.75fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-seven.products {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-seven.products.even {
  border-radius: 10px;
  background-color: #f8f9fb;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-seven.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-seven.checkbox-hide {
  display: none;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-three {
  padding-right: 20px;
  padding-left: 20px;
  justify-items: stretch;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-three.products {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-three.products.even {
  border-radius: 10px;
  background-color: #f8f9fb;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-three.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-three.checkbox-hide {
  display: none;
}

.add-prod-table-row._5-columns.with-checkb.product__text.column-three.ratio {
  -ms-grid-columns: 1fr 1fr 0.75fr;
  grid-template-columns: 1fr 1fr 0.75fr;
}

.add-prod-table-row._5-columns.with-checkb.delete--columns {
  -ms-grid-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.column-eight {
  padding-right: 20px;
  padding-left: 20px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.products {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns.with-checkb.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1fr 0.75fr 1fr 0.5fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1fr 0.75fr 1fr 0.5fr;
}

.add-prod-table-row._4-columns {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td.with-checkb {
  padding-right: 20px;
  padding-left: 20px;
  -ms-grid-columns: 0.75fr 1fr 1.25fr 1fr 0.75fr 1fr 0.5fr;
  grid-template-columns: 0.75fr 1fr 1.25fr 1fr 0.75fr 1fr 0.5fr;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text {
  -ms-grid-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-eight {
  padding-right: 20px;
  padding-left: 20px;
  -ms-grid-columns: 0.25fr 0.5fr 0.75fr 1fr 0.75fr 0.5fr 0.75fr 0.5fr;
  grid-template-columns: 0.25fr 0.5fr 0.75fr 1fr 0.75fr 0.5fr 0.75fr 0.5fr;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-eight.products {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-eight.products.even {
  border-radius: 10px;
  background-color: #f8f9fb;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-eight.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-4 {
  padding-right: 20px;
  padding-left: 20px;
  -ms-grid-columns: 0.75fr 1fr 1.25fr 1fr 0.75fr 1fr 0.5fr;
  grid-template-columns: 0.75fr 1fr 1.25fr 1fr 0.75fr 1fr 0.5fr;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-4.products {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-4.products.even {
  border-radius: 10px;
  background-color: #f8f9fb;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-4.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-four {
  padding-right: 20px;
  padding-left: 20px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-four.products {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-four.products.even {
  border-radius: 10px;
  background-color: #f8f9fb;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-four.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-three {
  padding-right: 20px;
  padding-left: 20px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-three.products {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-three.products.even {
  border-radius: 10px;
  background-color: #f8f9fb;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-three.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-three {
  padding-right: 20px;
  padding-left: 20px;
  -ms-grid-columns: 0.75fr 1fr 1.25fr 1fr 0.75fr 1fr 0.5fr;
  grid-template-columns: 0.75fr 1fr 1.25fr 1fr 0.75fr 1fr 0.5fr;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-three.products {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-three.products.even {
  border-radius: 10px;
  background-color: #f8f9fb;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-three.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-three {
  padding-right: 20px;
  padding-left: 20px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-three.products {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-three.products.even {
  border-radius: 10px;
  background-color: #f8f9fb;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-three.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-six {
  padding-right: 20px;
  padding-left: 20px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-six.products {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-six.products.even {
  border-radius: 10px;
  background-color: #f8f9fb;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-six.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-four {
  padding-right: 20px;
  padding-left: 20px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-four.products {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-four.products.even {
  border-radius: 10px;
  background-color: #f8f9fb;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-four.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-three {
  padding-right: 20px;
  padding-left: 20px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-three.products {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-three.products.even {
  border-radius: 10px;
  background-color: #f8f9fb;
}

.add-prod-table-row._5-columns-td.with-checkb.product__text.column-three.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1.25fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td.with-checkb.delete--columns {
  -ms-grid-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td.with-checkb.column-eight {
  padding-right: 20px;
  padding-left: 20px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td.with-checkb.products {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.add-prod-table-row._5-columns-td.with-checkb.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1fr 0.75fr 1fr 0.5fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1fr 0.75fr 1fr 0.5fr;
}

.dropdowns-filters {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.in-page-menu-link-2 {
  margin-top: 8px;
  margin-right: 12px;
  padding: 4px 23px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-style: solid;
  border-width: 1px;
  border-color: #e1e6f0;
  border-radius: 5px;
  background-color: transparent;
  color: #627183;
}

.in-page-menu-link-2.w--current {
  border-color: #1d94d2;
  border-radius: 5px;
  background-color: #1d94d2;
  color: #fff;
}

.in-page-menu-link-2.margin {
  margin-top: 12px;
}

.dashboard-container-2 {
  display: block;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.search-button-3 {
  display: block;
  width: 0px;
  height: 48px;
  margin-right: 10px;
  margin-left: 10px;
  padding: 0px;
  border-radius: 50px;
  background-color: #1d94d2;
  font-size: 15px;
}

.search-button-3:hover {
  background-color: #177aad;
}

.text-block-16 {
  font-size: 15px;
}

.text-block-17 {
  font-size: 15px;
}

.dropdown-toggle-6 {
  height: 48px;
  padding-left: 20px;
  border: 1px solid #e1e6f0;
  /* border-radius: 5px; */
  background-color: #f8f9fb;
  -webkit-box-align: center;
}

.hide {
  display: none;
}

.text-block-18 {
  text-transform: capitalize;
}

.section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  margin-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.text-block-19 {
  color: red;
}

.text-block-20 {
  color: red;
}

.text-block-21 {
  color: red;
}

.text-block-22 {
  color: red;
}

.text-block-23 {
  color: red;
}

.text-block-24 {
  color: red;
}

.button-3 {
  display: block;
  padding-right: 30px;
  padding-left: 30px;
  border-radius: 20px;
  background-color: #b6c5d4;
  color: #000;
  font-size: 16px;
  line-height: 16px;
}

.select-field {
  width: 50%;
  margin-right: 0px;
  margin-left: 0px;
}

.form-block-2 {
  margin-bottom: 0px;
}

.info-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 1px 1px 0 rgba(118, 135, 154, 0.24), 0 3px 8px 0 rgba(118, 135, 154, 0.08);
}

.info-section.archived {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.info-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 24px 36px 16px 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  line-height: 16px;
}

.info-header.no-space-bottom {
  padding-bottom: 0px;
}

.content {
  padding: 20px 36px 20px 20px;
}

.text-field-2 {
  max-width: 50%;
  border-radius: 12px;
}

.textarea {
  margin-bottom: 0px;
  border-radius: 8px;
}

.section-3 {
  display: block;
}

.confirm-action-modal {
  max-width: 600px;
  padding: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #627183;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 6px 0 #b6c5d4;
}

.button-4 {
  position: static;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  padding: 0px 5px;
  float: right;
  clear: left;
  border: 1px solid transparent;
  border-radius: 100%;
  background-color: transparent;
  outline-color: #b6c5d4;
  outline-offset: 0px;
  outline-style: solid;
  outline-width: 3px;
  color: #000;
}

.text-span {
  background-color: #000;
}

.button-5 {
  margin-left: 10px;
  float: right;
  background-color: transparent;
}

.button-5.cancel {
  padding-top: 5px;
  padding-bottom: 5px;
  border: 0px solid #000;
  border-radius: 20px;
  background-color: #b6c5d4;
  color: #000;
  font-size: 14px;
  line-height: 16px;
}

.button-5.proceed {
  padding-top: 5px;
  padding-bottom: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: red;
  border-radius: 20px;
  color: red;
  font-size: 14px;
  line-height: 16px;
}

.modal-wrapper {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 9998;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  overflow-y: auto;
}

.modal-text {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 0px;
  background-color: transparent;
}

.add-products-popup {
  max-width: 90%;
  min-width: 60%;
  padding: 20px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-style: solid;
  border-width: 1px;
  border-color: #627183;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 6px 0 #b6c5d4;
}

.text-field-3 {
  float: left;
  clear: none;
  border-radius: 5px;
  font-size: 14px;
  line-height: 14px;
}

.submit-button {
  height: 40px;
  margin-left: 10px;
  padding: 0px 40px;
  float: right;
  clear: none;
  border-radius: 20px;
  background-color: #b6c5d4;
  color: #000;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  -o-object-fit: fill;
  object-fit: fill;
}

.form-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: none;
  margin-top: 20px;
}

.form-block-4 {
  position: relative;
  display: block;
  max-width: none;
  min-width: 65%;
  float: left;
}

.field-label {
  font-size: 14px;
  line-height: 16px;
}

.form-block-5 {
  display: block;
  max-width: none;
  min-width: auto;
  float: right;
}

.container-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 60px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-3 {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.div-block-28 {
  display: block;
}

.heading {
  margin-left: 0px;
}

.checkbox-label-2 {
  margin-bottom: 0px;
  opacity: 0;
}

.select-field-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 90%;
  margin-top: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
}

.form-4 {
  max-width: 90%;
}

.form-4.add-products {
  max-width: none;
}

.form-block-6 {
  display: block;
  margin-bottom: 0px;
  padding-top: 0px;
}

.bold-text {
  color: #000;
}

.form-block-7 {
  margin-top: 100px;
  margin-bottom: 100px;
  padding-right: 10px;
}

.radio-button-label-2 {
  margin-bottom: 0px;
  opacity: 0;
}

.radio-button-field-2 {
  text-decoration: line-through;
}

.table-module-2-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 1px 1px 0 rgba(118, 135, 154, 0.24), 0 3px 8px 0 rgba(118, 135, 154, 0.08);
}

.table-module-2-copy.confirm-action {
  width: 400px;
}

.add-prod-table-content {
  padding-top: 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.add-prod-table-headers {
  padding: 16px 24px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 8px;
  background-color: #f8f9fb;
}

.add-prod-table-headers._6-columns {
  -webkit-box-pack: stretch;
  -webkit-justify-content: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -ms-grid-row-align: auto;
  align-self: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  grid-column-gap: 4px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.add-prod-table-headers._6-columns.with-checkbox {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr;
}

.add-prod-table-headers._6-columns.with-checkbox.column-eight {
  padding-right: 20px;
  padding-left: 20px;
  justify-items: start;
  -ms-grid-columns: 0.75fr 1fr 1.25fr 1fr 0.75fr 1fr 0.5fr;
  grid-template-columns: 0.75fr 1fr 1.25fr 1fr 0.75fr 1fr 0.5fr;
}

.add-prod-table-headers._6-columns.with-checkbox.column-eight.products {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.add-prod-table-headers._6-columns.with-checkbox.column-eight.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1fr 0.75fr 1fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1fr 0.75fr 1fr;
}

.text-field-4 {
  border-radius: 5px;
}

.text-field-5 {
  border-radius: 5px;
}

.table-row-2 {
  padding: 16px 24px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  font-size: 14px;
}

.table-row-2._5-columns {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.table-row-2._5-columns.with-checkb {
  padding-right: 20px;
  padding-left: 20px;
  -ms-grid-columns: 0.75fr 1fr 1.25fr 1fr 0.75fr 1fr 0.5fr;
  grid-template-columns: 0.75fr 1fr 1.25fr 1fr 0.75fr 1fr 0.5fr;
}

.table-row-2._5-columns.with-checkb.product__text {
  -ms-grid-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.table-row-2._5-columns.with-checkb.product__text.column-eight {
  padding-right: 20px;
  padding-left: 20px;
  -ms-grid-columns: 0.75fr 1fr 1.25fr 1fr 0.75fr 1fr 0.5fr;
  grid-template-columns: 0.75fr 1fr 1.25fr 1fr 0.75fr 1fr 0.5fr;
}

.table-row-2._5-columns.with-checkb.product__text.column-eight.products {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.table-row-2._5-columns.with-checkb.product__text.column-eight.products.even {
  border-radius: 10px;
  background-color: #f8f9fb;
}

.table-row-2._5-columns.with-checkb.product__text.column-eight.add-products {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.table-row-2._5-columns.with-checkb.product__text.column-eight.add-products.even {
  border-radius: 10px;
  background-color: #f8f9fb;
}

.table-row-2._5-columns.with-checkb.product__text.column-eight.confirm-action {
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
}

.table-row-2._5-columns.with-checkb.product__text.column-eight.confirm-action.even {
  border-radius: 10px;
  background-color: #f8f9fb;
}

.table-row-2._5-columns.with-checkb.product__text.column-eight.buttons {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 8px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}

.table-row-2._5-columns.with-checkb.product__text.column-eight.buttons.even {
  border-radius: 10px;
  background-color: #f8f9fb;
}

.table-row-2._5-columns.with-checkb.product__text.column-eight.add-attributes {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: 1.5fr 1fr 1fr;
  grid-template-columns: 1.5fr 1fr 1fr;
}

.table-row-2._5-columns.with-checkb.product__text.column-eight.add-attributes.even {
  border-radius: 10px;
  background-color: #f8f9fb;
}

.table-row-2._5-columns.with-checkb.delete--columns {
  -ms-grid-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr;
}

.table-row-2._5-columns.with-checkb.column-eight {
  padding-right: 20px;
  padding-left: 20px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.table-row-2._5-columns.with-checkb.products {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.table-row-2._5-columns.with-checkb.customers {
  -ms-grid-columns: 0.75fr 1fr 1.5fr 1fr 0.75fr 1fr 0.5fr;
  grid-template-columns: 0.75fr 1fr 1.5fr 1fr 0.75fr 1fr 0.5fr;
}

.table-row-2.coll-product-tab {
  -ms-grid-columns: 1fr 1fr 1fr 0.5fr;
  grid-template-columns: 1fr 1fr 1fr 0.5fr;
}

.dropdown-toggle-6-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 48px;
  padding-top: 16px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.select-button {
  margin-left: 10px;
  float: right;
  background-color: transparent;
}

.select-button.cancel {
  padding-top: 5px;
  padding-bottom: 5px;
  border: 0px solid #000;
  border-radius: 20px;
  background-color: #b6c5d4;
  color: #000;
  font-size: 14px;
  line-height: 16px;
}

.select-button.proceed {
  padding-top: 5px;
  padding-bottom: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #000;
  border-radius: 20px;
  color: #000;
  font-size: 14px;
  line-height: 16px;
}

.text-block-25 {
  text-transform: capitalize;
}

.button-6 {
  border-radius: 60px;
  background-color: #1d94d2;
}

.button-6:hover {
  background-color: #177aad;
}

.button-6.delete {
  background-color: #dc0004;
}

.button-6.delete:hover {
  background-color: #c64734;
}

.button-6.cancel {
  border-style: solid;
  border-width: 1px;
  border-color: #1d94d2;
  background-color: transparent;
  color: #1d94d2;
}

.button-6.cancel:hover {
  background-color: #1d94d2;
  color: #fff;
}

.button-6.confirm {
  padding-right: 40px;
  padding-left: 40px;
  background-color: #1d94d2;
}

.button-6.confirm:hover {
  background-color: #126590;
}

.button-6.cancel-copy {
  margin-right: 8px;
  padding-right: 40px;
  padding-left: 40px;
  border-style: solid;
  border-width: 1px;
  border-color: #1d94d2;
  background-color: transparent;
  color: #1d94d2;
}

.button-6.cancel-copy:hover {
  background-color: #1d94d2;
  color: #fff;
}

.button-6.text-center {
  width: 100%;
  background-color: #1d94d2;
  text-align: center;
}

.div-block-29 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.div-block-29.buttons {
  margin-top: 0px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: right;
}

.div-block-29.buttons.hide {
  display: none;
}

.div-block-29.left {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.checkbox-label-3 {
  display: none;
}

.checkbox-2 {
  width: 30px;
  height: 30px;
  margin-top: 0px;
  margin-left: 0px;
}

.checkbox-field-2 {
  margin-bottom: 0px;
  padding-left: 0px;
}

.button-7 {
  padding-right: 16px;
  padding-left: 16px;
  border-radius: 100px;
}

.container-3 {
  padding: 40px;
}

.body_modal {
  background-color: rgba(0, 0, 0, 0.61);
}

.column-15 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.status-header {
  display: none;
  margin-right: 10px;
  margin-left: 10px;
  padding: 5px 24px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 20px;
  background-color: #1d94d2;
  color: #fff;
}

.customer-link {
  color: #1d94d2;
  font-size: 12px;
}

._50per-block {
  width: 50%;
  text-align: left;
}

._50per-block.width50-per {
  width: 68%;
}

.heading-38 {
  font-size: 12px;
}

.section-4.hide {
  display: none;
}

.link-8 {
  color: #1d94d2;
  font-size: 12px;
  text-decoration: underline;
}

.timeline-gray-text {
  color: #627183;
  font-size: 12px;
}

.body-link-2 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  color: #464646;
}

.body-link-2.right {
  text-align: right;
}

.body-link-2:hover {
  color: #492799;
}

.link-9 {
  color: #1d94d2;
  font-size: 12px;
  text-decoration: underline;
}

.order-details-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 24px 36px 16px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.search-wrap {
  width: 100%;
}

.small-link-2 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  color: #1d94d2;
  font-size: 14px;
  line-height: 22px;
}

.small-link-2:hover {
  color: #492799;
}

.timeline__image {
  width: 50px;
  border-radius: 50px;
}

.table__wrraper {
  display: -ms-grid;
  display: grid;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.timeline-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.button-8 {
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 6px;
  background-color: #04a078;
  font-size: 12px;
}

.border-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 16px;
  padding: 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 3px 8px 0 rgba(118, 135, 154, 0.08), 0 1px 1px 0 rgba(118, 135, 154, 0.24);
}

.border-block.border-block--vertical {
  margin-top: 16px;
  padding-right: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-top: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  text-align: left;
}

.border-block.border-block--padding-none {
  overflow: hidden;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.timeline-header {
  font-size: 12px;
  font-weight: 500;
}

.timeline-header.date {
  position: absolute;
  left: 0%;
  top: -27px;
  right: 0%;
  bottom: auto;
  color: #627183;
  text-transform: uppercase;
}

.timeline-header.date.turn-off {
  display: none;
}

.div-block-37 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 20px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  border-radius: 6px;
}

.heading-40 {
  font-size: 12px;
  font-weight: 500;
}

.columns-12 {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 2px solid #b6c5d4;
}

.message-area {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 120.5%;
  margin-top: 50px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.message-area.margin-smaller {
  margin-top: 15px;
}

.heading-28 {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 5px;
  padding-right: 10px;
  font-size: 12px;
  font-weight: 400;
}

.table-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 22px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.table-column.table-column--content {
  position: relative;
  padding-right: 32px;
  padding-left: 32px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.table-column.table-column--content.grey {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.table-column.table-column--content.grey.flex {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-top: 1px solid #b6c5d4;
}

.table-column.table-column--content.table-subsection-center {
  width: 100%;
  padding-left: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.table-column.table-column--content.table-column--vertical {
  margin-top: 10px;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.table-column.table-column--content.dark {
  display: -ms-grid;
  display: grid;
  width: 100%;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  background-color: #fff;
}

.table-column.table-column--content.dark.items {
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.table-column.table-column--title {
  position: relative;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.table-column.table-column--title.table-column--padding-none {
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-bottom: 1px solid #b6c5d4;
}

.table-column.table-column--title.table-column--padding-none._24-margin {
  padding: 0px;
}

.table-column.table-column--title.table-column--padding-none.padding-16px {
  padding-top: 16px;
  padding-bottom: 16px;
}

.table-column.table-column--title.width {
  width: 100%;
}

.table-column.table-column--title.padding-16px {
  padding-right: 0px;
  padding-left: 0px;
}

.text-block-26 {
  color: #464646;
}

.customer-heading {
  font-size: 15px;
}

.columns-11 {
  width: 100%;
  padding-top: 15px;
}

.total-paid-box {
  width: 30%;
  padding-top: 4px;
  padding-bottom: 4px;
}

.total-paid-box.total-paid-box--bigger {
  width: 60%;
}

.subsection {
  width: 20px;
  height: 20px;
}

.search-button-2 {
  position: absolute;
  right: 20px;
  height: 30px;
  margin-top: 0px;
  margin-right: 0px;
  padding: 0px 7px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 8px;
  background-color: #1d94d2;
  color: #fff;
  font-size: 14px;
}

.search-button-2.re-des {
  right: 40px;
  height: auto;
  margin-right: -8px;
  padding: 11px 16px;
  border-radius: 5px;
  background-color: #1d94d2;
}

.icon__subsection {
  width: 24px;
  height: 24px;
}

.table-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.table-box.widthin--subsection {
  width: 14%;
}

.grey-line {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  height: 1px;
  margin-right: 15px;
  margin-left: 15px;
  background-color: #b6c5d4;
}

.grey-line.margin {
  margin-right: 32px;
  margin-left: 32px;
}

.timeline__content-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 83%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.heading-41 {
  font-size: 12px;
  font-weight: 400;
}

.small-text-2 {
  margin-bottom: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}

.small-text-2.bold {
  font-weight: 600;
}

.small-text-2.bold.caps {
  margin-bottom: 15px;
  text-transform: uppercase;
}

.small-text-2.text-grey-3 {
  color: #627183;
}

.small-text-2.margin-top {
  margin-top: 10px;
}

.border-block__title {
  margin-bottom: 0px;
  font-size: 26px;
}

.border-block__title.margin {
  margin-bottom: 8px;
}

.border-block__title.margin--30 {
  margin-bottom: 30px;
}

.indication-color-2 {
  width: 10px;
  height: 10px;
  margin-right: 8px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 100px;
}

.indication-color-2.bg-primary-green {
  background-color: #01dda5;
}

.timeline-block {
  margin-bottom: 100px;
  padding-right: 40px;
  padding-left: 40px;
}

.caption-large-3 {
  color: #627183;
  font-size: 12px;
  line-height: 10px;
  font-weight: 500;
  text-align: left;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.column-16 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.timeline__line {
  height: 100%;
  margin-top: 1px;
  margin-bottom: 1px;
  padding-right: 2px;
  background-color: #627183;
}

.heading-42 {
  padding-top: 25px;
  padding-bottom: 20px;
  padding-left: 30px;
  color: #b6c5d4;
  font-size: 12px;
}

.link-block-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 5px;
  border-style: solid;
  border-width: 2px;
  border-color: #b6c5d4;
  border-radius: 8px;
}

.link-block-3.margin {
  margin-right: 5px;
  border-width: 0px;
}

.vertical-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.timeline-bullet {
  margin-left: -0.5px;
  padding-right: 15px;
  padding-bottom: 15px;
  border-radius: 12px;
  background-color: #627183;
}

.timeline-bullet.active {
  background-color: #1d94d2;
}

.bsck-arrow {
  width: 25px;
  margin-top: 6px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none;
  border-width: 0px;
  border-color: #627183;
  border-radius: 6px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.image-7 {
  padding-right: 10px;
  padding-bottom: 0px;
}

.column-13 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.order-grid-grey-heading {
  margin-bottom: 0px;
  color: #627183;
  font-size: 12px;
  font-weight: 500;
}

.link-11 {
  color: #1d94d2;
  font-size: 12px;
  text-decoration: underline;
}

.heading-id {
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 20px;
}

.heading-id.heading-id--margin-none {
  width: 100%;
  margin-bottom: 0px;
  padding-left: 0px;
  line-height: 100%;
}

.heading-id.heading-id--margin-none.no-padding {
  margin-top: 0px;
}

.timeline__line-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 17%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.customer-content {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.small-text-3 {
  color: #000;
}

.table-link {
  border-radius: 3px;
  font-weight: 400;
  text-decoration: underline;
}

.table-link.product__text {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
  background-color: #e1e6f0;
  line-height: 20px;
  text-decoration: none;
}

.div-block-22 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.heading-29 {
  display: inline-block;
  margin-bottom: 0px;
  padding-top: 2px;
  color: #627183;
  font-size: 15px;
}

.timeline-title {
  margin-top: 45px;
  margin-bottom: 45px;
}

.search {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.search.search--margin-none {
  margin-bottom: 0px;
}

.horizontal-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: left;
}

.horizontal-wrapper.from-right {
  display: -ms-grid;
  display: grid;
  margin-top: 0px;
  padding-top: 0px;
  /* -webkit-box-orient: horizontal; */
  /* -webkit-box-direction: normal; */
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: start;
  -ms-flex-pack: start;
  justify-content: start;
  justify-items: end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  align-self: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.horizontal-wrapper.space-between {
  position: relative;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.horizontal-wrapper._4px {
  margin-bottom: 4px;
}

.horizontal-wrapper.from-left {
  margin-bottom: 0px;
  padding-top: 15px;
}

.fulfiled-box {
  padding-right: 30px;
  padding-left: 30px;
}

.order-table {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 1px 1px 0 rgba(118, 135, 154, 0.24), 0 3px 8px 0 rgba(118, 135, 154, 0.08);
}

.dropdown-toggle {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 20px;
  color: #627183;
  font-weight: 600;
}

.order-title {
  margin-bottom: 0px;
  font-size: 15px;
}

.order-title.analytics {
  padding-bottom: 5px;
  color: #25364e;
  font-size: 15px;
}

.border-block__date {
  padding-left: 48px;
  color: #627183;
  font-size: 12px;
}

.link {
  color: #25364e;
  text-decoration: none;
}

.link.border-none {
  color: #1d94d2;
  text-decoration: none;
}

.customer-avatar {
  width: 65px;
  height: 65px;
  margin-right: 8px;
  border-radius: 50px;
}

.heading-59 {
  margin-bottom: 0px;
  padding-left: 10px;
  color: #627183;
  font-size: 12px;
  font-weight: 400;
}

.timeline-bullet-padding {
  padding-top: 1px;
  padding-bottom: 1px;
  background-color: #f8f9fb;
}

.section-6.hide {
  display: none;
}

.search-input-radius {
  position: static;
  display: inline-block;
  width: 100%;
  height: 50px;
  margin-bottom: 0px;
  padding-right: 0px;
  padding-left: 10px;
  border-radius: 5px;
}

.div-block-33 {
  border: 1px none #000;
}

.grey-graph-title {
  display: inline-block;
  border-bottom: 2px none #627183;
  color: #627183;
  font-weight: 500;
}

.analytics-3-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.column-45 {
  padding-left: 0px;
}

.price-value {
  font-size: 25px;
}

.graph-subtitle {
  display: inline-block;
  border-bottom: 2px none #b6c5d4;
  color: #00162f;
}

.grey-data {
  color: #627183;
  font-size: 14px;
  font-weight: 500;
}

.grey-sub-title {
  margin-bottom: 15px;
  padding-left: 0px;
  color: #627183;
  font-size: 15px;
  font-weight: 400;
}

.grey-sub-title.analytics {
  display: inline-block;
  padding-left: 15px;
  font-size: 14px;
  font-weight: 500;
}

.image-17 {
  border: 1px none #000;
}

.image-18 {
  display: inline-block;
  height: 140px;
  width: 140px;
  min-width: 140px;
}

.green-text {
  color: #01dda5;
}

.image-16 {
  max-width: 20%;
}

.column-42 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.heading-53 {
  display: inline-block;
  margin-bottom: 0px;
  padding-left: 10px;
  font-size: 14px;
}

.search-button {
  padding-bottom: 20px;
  font-size: 14px;
}

.search-button.analystics {
  display: inline-block;
  margin-left: 0px;
  padding: 3px 10px;
}

.modules {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 1px 1px 0 rgba(118, 135, 154, 0.24), 0 3px 8px 0 rgba(118, 135, 154, 0.08);
}

.modules.analytics {
  padding-top: 30px;
  padding-right: 30px;
  padding-left: 30px;
}

.heading-56 {
  margin-bottom: 0px;
  padding-bottom: 20px;
}

.heading-55 {
  margin-bottom: 0px;
  font-size: 12px;
}

.column-43 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.dashboard-order-page {
  display: block;
  overflow: hidden;
  margin-left: 0px;
  padding: 30px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.space-left-small {
  margin-left: 10px;
}

.checkbox-header {
  display: none;
  max-width: none;
}

.icon-right-space {
  margin-right: 10px;
  padding-right: 0px;
}

.bg-primary-black {
  background-color: #000;
}

.margin {
  margin-bottom: 20px;
}

.form-block-9 {
  margin-bottom: 0px;
  padding-right: 20px;
  margin: 0 0 15px;
}

.form-block-9.larger {
  height: 60px;
}

.grid-11 {
  margin-top: 10px;
  grid-row-gap: 8px;
  -ms-grid-columns: 1fr 3fr 1fr;
  grid-template-columns: 1fr 3fr 1fr;
  -ms-grid-rows: auto auto auto auto auto;
  grid-template-rows: auto auto auto auto auto;
}

.tabs-2 {
  margin-top: 40px;
}

.heading-60 {
  margin-bottom: 16px;
}

.dropdown-5 {
  width: 168px;
  margin-right: 0px;
  margin-left: 0px;
  text-align: left;
}

.heading-62 {
  margin-bottom: 10px;
  padding-right: 20px;
  font-size: 18px;
  font-weight: 500;
  text-transform: none;
}

.heading-62._30px-margin {
  margin-left: 30px;
}

/* .dropdown-toggle-7 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  padding-right: 60px;
  padding-left: 20px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px none #000;
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba(37, 54, 78, 0.16);
} */

.dropdown-toggle-7 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  padding: 10px 50px 10px 20px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px none #000;
  border-radius: 8px;
  box-shadow: 0 0 5px 0 rgba(118, 135, 154, 0.25);
}

.dropdown-toggle-7._100-width {
  width: 100%;
}

.dropdown-toggle-7._100-width._20px-padding {
  padding-right: 20px;
  padding-left: 20px;
}

.dropdown-toggle-7.padding-16px {
  padding: 16px 60px 16px 20px;
}

.icon-7 {
  margin-right: 10px;
}

.icon-7._20px-margin {
  margin-right: 20px;
}

.icon-7._40px-margin-copy {
  margin-right: 10px;
}

.dropdown-link-3 {
  background-color: #fff;
}

.dropdown-list-7 {
  border-radius: 8px;
}

.dropdown-list-7.w--open {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 0 3px 0 rgba(37, 54, 78, 0.16);
}

.dropdown-6 {
  margin-right: 0px;
  margin-left: 0px;
}

.dropdown-6._100--width {
  width: 100%;
}

.div-block-38 {
  /* padding-right: 15px; */
  /* padding-left: 15px; */
  /* margin-bottom: 20px; */
  /* margin-top: 15px; */
}

.div-block-38.no-margin {
  margin-bottom: 0px;
}

.grid-12 {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.heading-63 {
  margin-bottom: 16px;
  font-weight: 400;
  text-align: left;
  text-transform: capitalize;
}

.heading-63._8px-margin {
  margin-bottom: 8px;
}

.text-block-30 {
  text-transform: capitalize;
}

.text-block-30.product__text {
  color: #00dc21;
  text-align: left;
}

.text-block-30.product__text.red {
  color: #dc0004;
}

.text-block-30.product__text {
  margin-right: 0px;
  color: #000;
  text-align: center;
}

.text-block-30._16px {
  margin-right: 0px;
  font-size: 16px;
  font-weight: 500;
}

.text-block-30._16px.link {
  margin-right: 0px;
  color: #1d94d2;
  text-decoration: underline;
}

.div-block-39 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.heading-64 {
  margin-top: 8px;
  margin-bottom: 16px;
  color: #a1a9b3;
  font-weight: 400;
}

.heading-64.error {
  color: #dc0004;
}

.button-9 {
  border-radius: 60px;
  background-color: #1d94d2;
}

.button-9:hover {
  background-color: #177aad;
}

.button-9.delete {
  background-color: #ec7028;
}

.button-9.delete:hover {
  background-color: #c64734;
}

.btn {
  margin-top: 20px;
  padding-right: 40px;
  padding-left: 40px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  border-radius: 40px;
  background-color: #dc0004;
  text-align: left;
}

.div-block-40 {
  display: -ms-grid;
  display: grid;
  justify-items: stretch;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-40._20px-margin {
  margin-bottom: 20px;
}

.div-block-41 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.div-block-42 {
  display: -ms-grid;
  display: grid;
  margin-top: 20px;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 8px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto auto auto auto auto;
  grid-template-rows: auto auto auto auto auto auto;
}

.div-block-42._2-rows {
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.div-block-42._4-colums {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.radio-button-field-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.radio-button-2 {
  margin-right: 8px;
}

.radio-button-label-3 {
  text-transform: capitalize;
}

.checkbox-label-4 {
  text-transform: capitalize;
}

.div-block-43 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.div-block-44 {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto 20px auto auto auto;
  grid-template-rows: auto 20px auto auto auto;
}

.div-block-44._4-row {
  -ms-grid-rows: auto 20px auto auto;
  grid-template-rows: auto 20px auto auto;
}

.img-div {
  height: 100px;
  background-color: #1d94d2;
}

.div-block-45 {
  height: 100%;
}

.div-block-46 {
  height: 100%;
}

.div-block-47 {
  height: 100%;
}

.div-block-48 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.dashboard-content-3 {
  padding-bottom: 30px;
}

@media screen and (min-width: 1280px) {
  .button.space-left-small {
    margin-left: 8px;
  }

  .nav-link.colored {
    -webkit-filter: none;
    filter: none;
  }

  .nav-icon {
    width: 24px;
    height: 24px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }

  .nav-icon.collection {
    height: 26px;
  }

  .table-module.overflow-auto {
    overflow: auto;
  }

  .text-block-15.product__text {
    color: #00dc21;
  }

  .text-block-15.product__text {
    color: #000;
  }

  .table-title-2 {
    text-align: center;
  }

  .product__text {
    font-size: 14px;
  }

  .dropdown-toggle-5 {
    display: block;
  }

  .status-2 {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  /* .status-2.product__text {
    text-align: left;
  } */

  .status-2.header {
    margin-right: 16px;
  }

  .table-module-2.overflow-auto {
    overflow: auto;
  }

  .searchbar.width {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .table-header-2.orderspage.full-width {
    padding-right: 24px;
    padding-left: 24px;
  }

  .dropdown-list-6 {
    display: none;
  }

  .searchbar_filters.align-lright {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .customer__search {
    margin-left: 0px;
  }

  .button-2.not-center.fixed-height {
    display: none;
    height: 34px;
  }

  .button-2.size__btn {
    margin-right: 0px;
    font-size: 15px;
  }

  .button-2.size__btn.large {
    width: auto;
    margin-left: 16px;
  }

  .button-2.analytics.fixed-height {
    display: none;
    height: 34px;
  }

  .caption-large-2 {
    font-size: 13px;
  }

  .table-headers-2._6-columns.with-checkbox {
    -ms-grid-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr;
  }

  .table-headers-2._6-columns.with-checkbox.column-eight {
    -ms-grid-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    grid-template-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
  }

  .table-headers-2._6-columns.with-checkbox.column-eight.customers {
    -ms-grid-columns: 0.75fr 1fr 1.25fr 1fr 0.75fr 0.75fr;
    grid-template-columns: 0.75fr 1fr 1.25fr 1fr 0.75fr 0.75fr;
  }

  .table-headers-2._6-columns.with-checkbox.column-eight.collections {
    justify-items: stretch;
  }

  .table-headers-2._6-columns.with-checkbox.column-eight.attributes {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .table-headers-2._6-columns.with-checkbox.column-three {
    -ms-grid-columns: 0.75fr 1fr 1fr;
    grid-template-columns: 0.75fr 1fr 1fr;
    -ms-grid-rows: auto 0px;
    grid-template-rows: auto 0px;
  }

  .table-headers-2._6-columns.with-checkbox.column-three {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .table-headers-2._6-columns.with-checkbox.column-six {
    -ms-grid-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr;
  }

  .table-headers-2._6-columns.with-checkbox.column-four {
    -ms-grid-columns: 0.75fr 1fr 1fr 1fr;
    grid-template-columns: 0.75fr 1fr 1fr 1fr;
  }

  .table-headers-2._6-columns.with-checkbox.column-two {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .table-headers-2._6-columns.with-checkbox.column-two.collections {
    justify-items: stretch;
  }

  .table-headers-2._6-columns.with-checkbox.column-seven {
    -ms-grid-columns: 0.75fr 1.25fr 1fr 1fr 0.75fr 0.75fr 0.75fr;
    grid-template-columns: 0.75fr 1.25fr 1fr 1fr 0.75fr 0.75fr 0.75fr;
  }

  .table-headers-2._6-columns.with-checkbox.column-seven.collections {
    justify-items: stretch;
  }

  .dropdown-list-2 {
    display: none;
  }

  .alert-module-2 {
    background-color: #58869d;
  }

  .add-prod-table-row._5-columns.with-checkb {
    -ms-grid-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    grid-template-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-eight {
    -ms-grid-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    grid-template-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    font-size: 14px;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-eight.customers {
    -ms-grid-columns: 0.75fr 1fr 1.25fr 1fr 0.75fr 0.75fr;
    grid-template-columns: 0.75fr 1fr 1.25fr 1fr 0.75fr 0.75fr;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-4 {
    -ms-grid-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    grid-template-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    font-size: 14px;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-four {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    font-size: 14px;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-three {
    -ms-grid-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    grid-template-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    font-size: 14px;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-three {
    -ms-grid-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    grid-template-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    font-size: 14px;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-three {
    -ms-grid-columns: 0.75fr 1fr 1fr;
    grid-template-columns: 0.75fr 1fr 1fr;
    font-size: 14px;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-six {
    -ms-grid-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr;
    font-size: 14px;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-four {
    -ms-grid-columns: 0.75fr 1fr 1fr 1fr;
    grid-template-columns: 0.75fr 1fr 1fr 1fr;
    font-size: 14px;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-three {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    font-size: 14px;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-two {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    font-size: 14px;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-seven {
    -ms-grid-columns: 0.75fr 1.25fr 1fr 1fr 0.75fr 0.75fr 0.75fr;
    grid-template-columns: 0.75fr 1.25fr 1fr 1fr 0.75fr 0.75fr 0.75fr;
    font-size: 14px;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-three {
    -ms-grid-columns: 1fr 1.25fr 1fr;
    grid-template-columns: 1fr 1.25fr 1fr;
    font-size: 14px;
  }

  .add-prod-table-row._5-columns-td.with-checkb {
    -ms-grid-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    grid-template-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
  }

  .add-prod-table-row._5-columns-td.with-checkb.product__text.column-eight {
    -ms-grid-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    grid-template-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    font-size: 14px;
  }

  .add-prod-table-row._5-columns-td.with-checkb.product__text.column-4 {
    -ms-grid-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    grid-template-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    font-size: 14px;
  }

  .add-prod-table-row._5-columns-td.with-checkb.product__text.column-four {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    font-size: 14px;
  }

  .add-prod-table-row._5-columns-td.with-checkb.product__text.column-three {
    -ms-grid-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    grid-template-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    font-size: 14px;
  }

  .add-prod-table-row._5-columns-td.with-checkb.product__text.column-three {
    -ms-grid-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    grid-template-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    font-size: 14px;
  }

  .add-prod-table-row._5-columns-td.with-checkb.product__text.column-three {
    -ms-grid-columns: 0.75fr 1fr 1fr;
    grid-template-columns: 0.75fr 1fr 1fr;
    font-size: 14px;
  }

  .add-prod-table-row._5-columns-td.with-checkb.product__text.column-six {
    -ms-grid-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr;
    font-size: 14px;
  }

  .add-prod-table-row._5-columns-td.with-checkb.product__text.column-four {
    -ms-grid-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    grid-template-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    font-size: 14px;
  }

  .add-prod-table-row._5-columns-td.with-checkb.product__text.column-three {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    font-size: 14px;
  }

  .add-prod-table-row._5-columns-td.with-checkb.column-eight {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .dropdowns-filters {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .info-section.overflow-auto {
    overflow: auto;
  }

  .table-module-2-copy.overflow-auto {
    overflow: auto;
  }

  .add-prod-table-headers._6-columns.with-checkbox {
    -ms-grid-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-headers._6-columns.with-checkbox.column-eight {
    -ms-grid-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    grid-template-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
  }

  .table-row-2._5-columns.with-checkb {
    -ms-grid-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    grid-template-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
  }

  .table-row-2._5-columns.with-checkb.product__text.column-eight {
    -ms-grid-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    grid-template-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    font-size: 14px;
  }

  .table-row-2._5-columns.with-checkb.column-eight {
    -ms-grid-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    grid-template-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
  }

  .text-block-5 {
    font-size: 15px;
  }

  .column-15 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .customer-link {
    padding-left: 0px;
    color: #348cb9;
  }

  .section-4 {
    padding-bottom: 36px;
  }

  .link-8 {
    padding-right: 20px;
  }

  .timeline-gray-text {
    padding-left: 20px;
    color: rgba(0, 0, 0, 0.39);
    font-size: 15px;
  }

  .link-9 {
    display: block;
    padding-left: 0px;
    color: #348cb9;
    text-decoration: underline;
  }

  .order-details-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .link-10 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 20px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .heading-28 {
    display: inline-block;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 5px;
    border-radius: 12px;
    background-color: transparent;
    font-size: 12px;
    font-weight: 400;
  }

  .link-block {
    padding-right: 40px;
    padding-bottom: 40px;
  }

  .table-column.table-column--title.table-column--padding-none {
    padding-right: 0px;
    padding-left: 0px;
  }

  .table-column.table-column--title.table-column--padding-none._24-margin {
    padding-right: 24px;
    padding-left: 24px;
  }

  .column-14 {
    background-color: transparent;
  }

  .customer-heading {
    font-size: 19px;
  }

  .columns-11 {
    padding-top: 15px;
    padding-left: 0px;
  }

  .search-button-2 {
    position: absolute;
    right: 0px;
    display: inline-block;
    margin-top: 0px;
    margin-right: 30px;
  }

  .link-block1 {
    padding-right: 40px;
    padding-bottom: 40px;
  }

  .border-block__title {
    font-size: 20px;
  }

  .icon-3 {
    margin-top: 2px;
    margin-bottom: 0px;
  }

  .div-divider2 {
    height: 2px;
    margin-top: 21px;
    margin-bottom: 20px;
    padding-bottom: 0px;
    background-color: #cccbcb;
  }

  .link-block-3 {
    display: inline-block;
    padding: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 2px;
    border-color: #b6c5d4;
    border-radius: 12px;
  }

  .bsck-arrow {
    width: 35px;
    margin: 5px;
  }

  .heading-43 {
    padding-left: 20px;
    font-size: 15px;
    font-weight: 400;
  }

  .column-13 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .order-grid-grey-heading {
    padding-left: 0px;
    color: #627183;
    font-size: 12px;
    font-weight: 500;
  }

  .div-block-16 {
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 19px;
    background-color: #fff;
    box-shadow: 1px 1px 12px -5px #000;
  }

  .link-11 {
    padding-left: 0px;
    color: #42afe7;
  }

  .heading-id {
    display: inline-block;
    padding-right: 15px;
    padding-left: 10px;
  }

  .heading-29 {
    display: inline-block;
    margin-bottom: 0px;
    padding-top: 2px;
    padding-right: 20px;
    color: #627183;
    font-size: 15px;
    font-weight: 600;
  }

  .search {
    position: static;
  }

  .horizontal-wrapper.from-right {
    max-width: 350px;
  }

  .order-table {
    margin-bottom: 20px;
    padding-bottom: 30px;
  }

  .dropdown-toggle {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    color: #627183;
    font-weight: 600;
  }

  .order-title {
    font-size: 20px;
  }

  .order-title.analytics {
    font-size: 20px;
  }

  .border-block__date {
    padding-left: 60px;
    color: #627183;
    font-size: 15px;
    font-weight: 400;
  }

  .link {
    color: #1d94d2;
    font-size: 1.5vw;
    text-decoration: underline;
  }

  .heading-32 {
    padding-left: 20px;
  }

  .search-input-radius {
    display: inline-block;
    width: 100%;
    height: 60px;
    margin-bottom: 0px;
    padding: 8px 12px 9px;
  }

  .modules {
    margin-bottom: 10px;
    padding-bottom: 40px;
  }

  .tabs {
    margin-top: 0px;
  }

  .text-block-27.red {
    color: #dc0004;
  }

  .text-block-28 {
    padding-left: 0px;
  }

  .heading-61 {
    margin-bottom: -31px;
  }

  .text-block-30.product__text {
    color: #00dc21;
  }

  .text-block-30.product__text {
    color: #000;
  }
}

@media screen and (min-width: 1440px) {
  img {
    display: block;
  }

  .sidebar-content {
    width: 325px;
  }

  .indication-color.bg-primary-green.dropdown {
    background-color: #fff;
  }

  .project-avatars {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .avatar-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .table-avatar-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .kanban-avatar-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .presentation-hero-section {
    padding-top: 120px;
    text-align: center;
  }

  .presentation-title-wrapper {
    margin-right: auto;
    margin-bottom: 72px;
    margin-left: auto;
    font-size: 20px;
  }

  .dashboard-mockup {
    margin-right: auto;
    margin-left: auto;
  }

  .presentation-section {
    padding-top: 120px;
    padding-bottom: 120px;
    text-align: center;
  }

  .presentation-category-wrapper {
    margin-right: auto;
    margin-bottom: 72px;
    margin-left: auto;
    font-size: 20px;
  }

  .presentation-cta {
    margin-right: auto;
    margin-bottom: 72px;
    margin-left: auto;
    font-size: 20px;
  }

  .dashboard-banner {
    margin-left: 325px;
  }

  .search-input {
    margin-bottom: 0px;
  }

  .div-block-27 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 20px;
    height: 20px;
    margin-left: 0px;
    padding-right: 20px;
    padding-bottom: 25px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .status-2 {
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
  }

  .searchbar {
    width: 50%;
  }

  .searchbar.width {
    width: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .table-header-2.orderspage.full-width {
    padding-left: 30px;
  }

  .customer__search {
    margin-left: 0px;
  }

  .button-2 {
    margin-right: 0px;
    margin-left: 0px;
  }

  .button-2.size__btn {
    margin-left: 20px;
  }

  .dashboard-content-2 {
    margin-left: 325px;
  }

  .table-headers-2._6-columns.with-checkbox.column-eight {
    -ms-grid-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.75fr;
    grid-template-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.75fr;
  }

  .table-headers-2._6-columns.with-checkbox.column-three {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .table-headers-2._6-columns.with-checkbox.column-three {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .table-headers-2._6-columns.with-checkbox.column-six {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .table-headers-2._6-columns.with-checkbox.column-four {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .table-headers-2._6-columns.with-checkbox.column-two {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .table-headers-2._6-columns.with-checkbox.column-seven {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns.with-checkb {
    -ms-grid-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.75fr;
    grid-template-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.75fr;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-eight {
    -ms-grid-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.75fr;
    grid-template-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.75fr;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-4 {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-four {
    grid-auto-columns: 0.25fr;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-three {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-three {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-three {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-six {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-three {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-two {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-seven {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-three {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns-td.with-checkb {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns-td.with-checkb.product__text.column-eight {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns-td.with-checkb.product__text.column-4 {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns-td.with-checkb.product__text.column-four {
    grid-auto-columns: 0.25fr;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns-td.with-checkb.product__text.column-three {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns-td.with-checkb.product__text.column-three {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns-td.with-checkb.product__text.column-three {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns-td.with-checkb.product__text.column-six {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns-td.with-checkb.product__text.column-four {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns-td.with-checkb.product__text.column-three {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns-td.with-checkb.column-eight {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .search-button-3 {
    margin-left: 8px;
  }

  .dropdown-toggle-6 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .add-prod-table-headers._6-columns.with-checkbox.column-eight {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .table-row-2._5-columns.with-checkb {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .table-row-2._5-columns.with-checkb.product__text.column-eight {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .table-row-2._5-columns.with-checkb.column-eight {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .dropdown-toggle-6-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .text-block-5 {
    color: #627183;
    font-size: 15px;
    font-weight: 600;
  }

  .customer-link {
    color: #1d94d2;
  }

  .section-4 {
    padding-bottom: 20px;
    border-radius: 20px;
    background-color: transparent;
  }

  .link-8 {
    color: #1d94d2;
  }

  .link-9 {
    padding-left: 0px;
  }

  .order-details-header {
    padding-right: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .link-10 {
    padding-right: 0px;
    color: #1d94d2;
  }

  .timeline-header {
    padding-top: 4px;
    font-size: 15px;
  }

  .heading-28 {
    display: inline-block;
    margin-top: 0px;
    padding-right: 5px;
  }

  .link-block {
    padding-right: 40px;
    padding-bottom: 40px;
    border: 1px solid #000;
    border-radius: 10px;
    background-image: url("../images/chevron-right.svg");
    background-position: 0px 0px;
    background-size: cover;
  }

  .column-14 {
    background-color: transparent;
  }

  .search-button-2 {
    position: absolute;
    right: 0.375px;
    display: inline-block;
    margin-top: 0px;
    margin-right: 20px;
    border-radius: 8px;
    background-color: #b6c5d4;
    color: #627183;
  }

  .link-block1 {
    width: 40px;
    height: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    border: 1px solid #000;
    border-radius: 9px;
    background-image: url("../images/1024px-Feather-arrows-chevron-left.svg.png");
    background-position: 0px 0px;
    background-size: cover;
  }

  .icon-3 {
    margin-top: 5px;
    color: #627183;
  }

  .link-block-3 {
    border-width: 2px;
    border-color: #b6c5d4;
  }

  .bsck-arrow {
    width: 40px;
    margin: 5px;
  }

  .image-7 {
    width: 30px;
    margin-right: 10px;
    padding-right: 0px;
    padding-bottom: 0px;
  }

  .column-13 {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .heading-id {
    display: inline-block;
    padding-right: 15px;
    font-size: 2vw;
  }

  .div-block-22 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .heading-29 {
    display: inline-block;
    padding-top: 0px;
    padding-right: 5px;
    font-size: 15px;
    font-weight: 600;
  }

  .search {
    margin-top: 10px;
  }

  .horizontal-wrapper.from-right {
    max-width: 350px;
  }

  .order-table {
    margin-bottom: 20px;
  }

  .dropdown-toggle {
    padding-top: 0px;
    padding-left: 0px;
  }

  .order-title {
    font-size: 20px;
  }

  .order-title.analytics {
    font-size: 22px;
  }

  .border-block__date {
    padding-left: 64px;
    font-size: 15px;
    font-weight: 400;
  }

  .link {
    color: #1d94d2;
    font-size: 1.5vw;
    text-decoration: underline;
  }

  .search-input-radius {
    display: inline-block;
    width: 100%;
    height: 60px;
    padding-top: 8px;
  }

  .image-16 {
    display: inline-block;
  }

  .search-button {
    font-size: 16px;
  }

  .modules {
    padding-top: 10px;
    padding-bottom: 35px;
  }

  .dashboard-order-page {
    margin-left: 325px;
  }

  .dashboard-content-3 {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 1920px) {
  .bg-primary-rose.style-guide-color {
    background-color: #dc0004;
  }

  .dashboard-content {
    padding: 30px 0px;
  }

  .indication-color.bg-primary-rose {
    background-color: #dc0004;
  }

  .dashboard-banner {
    display: block;
    /* margin-bottom: 0px;
    margin-left: 0px; */
  }

  .search-input {
    display: inline-block;
    width: 400px;
    margin-top: 0px;
    margin-left: 0px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    font-size: 16px;
  }

  .table-content-2 {
    padding-right: 10px;
    padding-left: 10px;
  }

  .product__text {
    color: #151515;
    font-size: 16px;
  }

  .div-block-27 {
    display: block;
    width: 10px;
    height: 10px;
    margin-left: 0%;
    padding-right: 30px;
    padding-bottom: 35px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: #627183;
    border-radius: 6px;
    text-align: left;
  }

  .dropdown-4 {
    display: none;
    margin-right: 0px;
    margin-left: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .status-2.header {
    padding: 10px;
    background-color: #f8f9fb;
  }

  .searchbar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
    margin-top: 0px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .searchbar.width {
    width: 100%;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .table-header-2.orderspage {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .table-header-2.orderspage.full-width {
    display: block;
    padding-right: 40px;
    padding-left: 40px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .dropdown-list-6 {
    display: none;
  }

  .dropdown-list-6.w--open {
    display: block;
    overflow: visible;
  }

  .searchbar_filters {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .searchbar_filters.width {
    width: 100%;
  }

  .searchbar_filters.align-lright {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .customer__search {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .dropdown-link {
    margin-right: 0px;
    background-color: #fff;
  }

  .button-2 {
    margin-right: 0px;
    margin-left: 13px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 12px;
  }

  .button-2.not-center {
    display: block;
    margin-left: 20px;
  }

  .button-2.size__btn.status {
    margin-right: 20px;
    margin-left: 0px;
    border-style: solid;
    border-width: 1px;
    border-color: #b6c5d4;
    background-color: #e1e6f0;
    color: #000;
  }

  .button-2.analytics {
    display: block;
    margin-left: 20px;
  }

  .caption-large-2 {
    font-size: 14px;
  }

  .collection__title--wrraper.space-between {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .table-headers-2._6-columns.with-checkbox {
    -ms-grid-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr;
  }

  .table-headers-2._6-columns.with-checkbox.column-eight {
    -ms-grid-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.75fr;
    grid-template-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.75fr;
  }

  .table-headers-2._6-columns.with-checkbox.column-eight.collections {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .table-headers-2._6-columns.with-checkbox.column-three {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .table-headers-2._6-columns.with-checkbox.column-three {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .table-headers-2._6-columns.with-checkbox.column-six {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .table-headers-2._6-columns.with-checkbox.column-two {
    -ms-grid-columns: 0.25fr 0.75fr 1.25fr 1.25fr 1.25fr 0.75fr 1fr 0.75fr;
    grid-template-columns: 0.25fr 0.75fr 1.25fr 1.25fr 1.25fr 0.75fr 1fr 0.75fr;
  }

  .table-headers-2._6-columns.with-checkbox.column-seven {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .dropdown-list-2 {
    display: block;
  }

  .alert-module-2 {
    background-color: #1d94d2;
  }

  .no-space-bottom-2 {
    font-size: 28px;
  }

  .add-prod-table-row._5-columns.with-checkb {
    -ms-grid-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.75fr;
    grid-template-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.75fr;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text {
    -ms-grid-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-eight {
    -ms-grid-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.75fr;
    grid-template-columns: 0.75fr 1fr 1fr 1fr 1fr 1fr 0.75fr;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-4 {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-four {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-three {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-three {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-three {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-six {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-four {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-three {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-two {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-seven {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns.with-checkb.product__text.column-three {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns.with-checkb.column-eight {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .add-prod-table-row._4-columns {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns-td.with-checkb {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns-td.with-checkb.product__text {
    -ms-grid-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns-td.with-checkb.product__text.column-eight {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns-td.with-checkb.product__text.column-4 {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns-td.with-checkb.product__text.column-four {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns-td.with-checkb.product__text.column-three {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns-td.with-checkb.product__text.column-three {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns-td.with-checkb.product__text.column-three {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns-td.with-checkb.product__text.column-six {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns-td.with-checkb.product__text.column-four {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns-td.with-checkb.product__text.column-three {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .add-prod-table-row._5-columns-td.with-checkb.column-eight {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .in-page-menu-link-2.w--current {
    border-color: #1d94d2;
    background-color: #1d94d2;
  }

  .search-button-3 {
    background-color: #1d94d2;
    font-size: 16px;
  }

  .text-block-16 {
    font-size: 16px;
  }

  .text-block-17 {
    font-size: 16px;
  }

  .section {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .section-2 {
    display: block;
  }

  .info-section {
    margin-bottom: 0px;
  }

  .info-header.no-space-bottom {
    padding-bottom: 0px;
  }

  .content {
    margin-bottom: 0px;
  }

  .form-block-3 {
    margin: 0px;
  }

  .text-field-2 {
    max-width: 50%;
    min-width: auto;
  }

  .textarea {
    margin-bottom: 0px;
  }

  .form-block-7 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .add-prod-table-content {
    padding-right: 10px;
    padding-left: 10px;
  }

  .add-prod-table-headers._6-columns.with-checkbox {
    -ms-grid-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr;
  }

  .add-prod-table-headers._6-columns.with-checkbox.column-eight {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .table-row-2._5-columns.with-checkb {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .table-row-2._5-columns.with-checkb.product__text {
    -ms-grid-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .table-row-2._5-columns.with-checkb.product__text.column-eight {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .table-row-2._5-columns.with-checkb.column-eight {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .button-6.delete {
    border-style: solid;
    border-width: 1px;
    border-color: #dc0004;
    background-color: #fff;
    color: #dc0004;
    text-align: center;
  }

  .button-6.delete:hover {
    background-color: #dc0004;
    color: #fff;
  }

  .button-6.cancel {
    text-align: center;
  }

  .text-block-5 {
    font-size: 20px;
    font-weight: 500;
  }

  .customer-link {
    padding-left: 0px;
    color: #1d94d2;
    font-size: 20px;
    font-weight: 500;
  }

  ._50per-block {
    width: 21%;
  }

  ._50per-block.width50-per {
    width: 50%;
  }

  .column-52 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .heading-38 {
    display: inline-block;
    margin-bottom: 0px;
    padding-bottom: 20px;
    padding-left: 0px;
    font-size: 20px;
  }

  .div-block-13 {
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding-right: 170px;
    padding-left: 160px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .section-4 {
    display: block;
    min-height: 100px;
    margin-right: 0px;
    margin-bottom: 20px;
    margin-left: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .link-8 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 10px;
    padding-right: 20px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    color: #1d94d2;
    font-size: 19px;
    text-decoration: underline;
  }

  .timeline-gray-text {
    margin-bottom: 20px;
    padding-top: 3px;
    padding-right: 0px;
    padding-left: 0px;
    color: rgba(0, 0, 0, 0.48);
    font-size: 20px;
    font-weight: 500;
  }

  .link-9 {
    display: block;
    padding-left: 0px;
    color: #1d94d2;
    font-size: 22px;
    text-decoration: underline;
  }

  .order-details-header {
    padding-left: 30px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .link-10 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    color: #1d94d2;
    font-size: 19px;
    text-decoration: underline;
  }

  .div-block-14 {
    display: block;
    width: 80%;
    padding-right: 0px;
  }

  .button-8 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 20px;
    padding-top: 9px;
    padding-bottom: 9px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 6px;
    background-color: #04a078;
    color: #fff;
    font-size: 20px;
  }

  .border-block {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .border-block.border-block--vertical {
    margin-top: 20px;
    margin-bottom: 16px;
  }

  .border-block.border-block--padding-none {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .border-block.header {
    margin-bottom: 16px;
  }

  .timeline-header {
    display: block;
    margin-bottom: 20px;
    padding-top: 3px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    color: #25364e;
    font-size: 16px;
  }

  .div-block-37 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 30px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .heading-40 {
    display: inline-block;
    padding-left: 15px;
    color: #242424;
    font-size: 20px;
  }

  .columns-12 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 2px solid #b6c5d4;
  }

  .heading-28 {
    display: inline-block;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 10px;
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 20px;
    background-color: transparent;
    color: #fff;
    font-size: 14px;
  }

  .link-block {
    width: 40px;
    height: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    background-image: url("../images/chevron-right.svg");
    background-position: 0px 0px;
    background-size: cover;
  }

  .form-block-8 {
    margin-bottom: 0px;
  }

  .table-column.table-column--content.grey {
    padding-top: 16px;
    padding-bottom: 15px;
    border-radius: 6px;
  }

  .table-column.table-column--content.dark {
    width: 100%;
    border-radius: 6px;
  }

  .table-column.table-column--title {
    width: 100%;
  }

  .table-column.table-column--title.width {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .column-18 {
    display: block;
    padding-top: 0px;
    padding-right: 30px;
    padding-left: 0px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .column-14 {
    background-color: transparent;
  }

  .customer-heading {
    margin-bottom: 0px;
    padding-bottom: 30px;
    font-size: 21px;
  }

  .columns-11 {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }

  .search-button-2 {
    position: absolute;
    right: 3px;
    display: inline-block;
    margin-top: 0px;
    margin-right: 30px;
    padding: 3px 10px;
    border-radius: 11px;
    background-color: #1d94d2;
    color: #fff;
    font-size: 18px;
  }

  .table-box.widthin--subsection {
    width: 14%;
  }

  .heading-41 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    font-size: 20px;
  }

  .link-block1 {
    width: auto;
    margin-right: 10px;
    padding-right: 40px;
    padding-bottom: 40px;
    background-image: url("../images/1024px-Feather-arrows-chevron-left.svg.png");
    background-position: 0px 0px;
    background-size: cover;
    color: #070000;
  }

  .border-block__title {
    padding-top: 0px;
    padding-left: 0px;
    font-size: 28px;
  }

  .icon-3 {
    margin-top: 5px;
  }

  .caption-large-3 {
    font-size: 12px;
  }

  .text-span-4 {
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
    color: #1d94d2;
  }

  .column-16 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .div-divider2 {
    width: auto;
    height: 2px;
    margin-top: 21px;
    margin-bottom: 20px;
    padding-bottom: 0px;
    border: 1px none #b6b6b6;
    background-color: #cccbcb;
  }

  .heading-42 {
    padding-top: 25px;
    padding-bottom: 20px;
    padding-left: 30px;
    border-bottom: 2px solid #b6c5d4;
    color: rgba(128, 128, 128, 0.74);
    font-size: 20px;
  }

  .link-block-3 {
    overflow: visible;
    margin-right: 5px;
    margin-left: 0px;
    padding: 0px;
    border-style: solid;
    border-width: 2px;
    border-color: #b6c5d4;
    border-radius: 10px;
    background-color: transparent;
    -o-object-fit: fill;
    object-fit: fill;
  }

  .bsck-arrow {
    width: 25px;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .heading-43 {
    padding-left: 0px;
    font-weight: 500;
  }

  .image-7 {
    display: block;
    width: 30px;
    max-height: none;
    max-width: none;
    min-width: 20px;
    margin-right: 10px;
    margin-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }

  .column-13 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: flex-end;
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }

  .div-block-17 {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    padding-left: 0px;
  }

  .order-grid-grey-heading {
    padding-right: 0px;
    padding-left: 0px;
    color: rgba(0, 0, 0, 0.48);
    font-size: 20px;
    font-weight: 500;
  }

  .div-block-16 {
    margin-bottom: 23px;
    padding-bottom: 50px;
    border-radius: 19px;
    background-color: #fff;
    box-shadow: 6px 6px 18px -12px #000;
  }

  .link-11 {
    display: inline-block;
    padding-top: 10px;
    padding-left: 0px;
    color: #348cb9;
    font-size: 20px;
    text-decoration: underline;
  }

  .heading-id {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 30px;
  }

  .heading-id.heading-id--margin-none {
    margin-top: -16px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    font-size: 24px;
  }

  .div-block-22 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .heading-29 {
    display: inline-block;
    padding-top: 0px;
    padding-right: 20px;
    padding-left: 0px;
    color: #627183;
    font-size: 20px;
  }

  .search {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 10px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .horizontal-wrapper.from-right {
    max-width: 500px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .horizontal-wrapper.from-left {
    display: -ms-grid;
    display: grid;
    padding-top: 20px;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    border: 1px none #000;
  }

  .fulfiled-box {
    padding-top: 20px;
    padding-right: 30px;
    padding-left: 30px;
    border-radius: 19px;
    background-color: #fff;
  }

  .order-table {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .dropdown-toggle {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }

  .order-title {
    font-size: 25px;
  }

  .order-title.analytics {
    font-size: 30px;
  }

  .border-block__date {
    padding-left: 70px;
    font-size: 20px;
  }

  .link {
    color: #1d94d2;
    font-size: 1.5vw;
    font-weight: 500;
    text-decoration: underline;
  }

  .link.border-none {
    color: #1d94d2;
    font-size: 20px;
  }

  .heading-32 {
    padding-top: 0px;
    padding-left: 0px;
  }

  .heading-59 {
    margin-bottom: 0px;
    padding-left: 10px;
    color: #627183;
    font-size: 18px;
  }

  .section-6 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .search-input-radius {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 60px;
    margin-bottom: 0px;
  }

  .grid-10 {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .grey-sub-title.analytics {
    font-size: 18px;
  }

  .grey-sub-title.no-space-bottom {
    font-size: 15px;
  }

  .image-16 {
    display: inline-block;
  }

  .heading-53 {
    font-size: 16px;
  }

  .search-button {
    display: block;
    margin-left: 20px;
  }

  .search-button.analystics {
    display: inline-block;
  }

  .modules {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 991px) {
  ._4-grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  ._3-grid {
    width: 100%;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .style-guide-section {
    padding: 80px 8px;
  }

  .flex {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  ._2-grid {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .dashboard-sidebar {
    z-index: 500;
  }

  .sidebar-content {
    -webkit-transform: translate(-250px, 0px);
    -ms-transform: translate(-250px, 0px);
    transform: translate(-250px, 0px);
  }

  .sidebar-logo-wrapper {
    padding-bottom: 24px;
  }

  .hidden {
    display: none;
  }

  .mobile-bar {
    display: block;
  }

  .dashboard-content {
    margin-left: 0px;
  }

  .charts-preview {
    height: 280px;
  }

  .profile-picture {
    width: 80px;
    height: 80px;
  }

  .profile-avatar {
    margin-bottom: 16px;
    margin-left: 16px;
  }

  .mobile-bottom-spacing {
    height: 60px;
  }

  .team-picture {
    width: 80px;
    height: 80px;
  }

  .form-grid {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .password-reset-grid {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .invoice-contacts {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .presentation-section.cta {
    background-image: none;
    background-position: 0px 0px;
    background-size: auto;
    background-repeat: repeat;
  }

  .presentation-hero-bg {
    height: 730px;
  }

  .presentation-grid {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .presentation-grid._3-column {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .dashboard-banner {
    margin-left: 0px;
  }

  .dashboard-content-2 {
    margin-left: 0px;
  }

  .collection__title--wrraper.space-between {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .confirm-action-modal {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .button-4 {
    position: absolute;
    margin-top: 10px;
    margin-right: 10px;
  }

  .button-5.cancel {
    margin-top: 20px;
    margin-left: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 20px;
    background-color: #b6c5d4;
    color: #000;
    font-size: 14px;
    line-height: 16px;
  }

  .button-5.proceed {
    margin-top: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .add-products-popup {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .select-button.cancel {
    margin-top: 20px;
    margin-left: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 20px;
    background-color: #b6c5d4;
    color: #000;
    font-size: 14px;
    line-height: 16px;
  }

  .select-button.proceed {
    margin-top: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .heading-28 {
    display: inline-block;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    font-size: 10px;
  }

  .search-button-2 {
    font-size: 12px;
  }

  .link-block-3 {
    border-style: solid;
    border-width: 2px;
    border-color: #b6c5d4;
    border-radius: 8px;
  }

  .bsck-arrow {
    width: 30px;
    padding: 0px;
  }

  .column-13 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .heading-id {
    margin-top: 12px;
    padding-left: 10px;
  }

  .div-block-22 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .heading-29 {
    color: #627183;
  }

  .dropdown-toggle {
    color: #627183;
    font-weight: 600;
  }

  .border-block__date {
    padding-left: 40px;
  }

  .dashboard-order-page {
    margin-left: 0px;
  }

  .text-block-27 {
    color: #dc0004;
    text-align: left;
  }

  .text-block-27.red {
    border-style: solid;
    border-width: 1px;
    border-color: red;
    color: #753335;
  }
}

@media screen and (max-width: 767px) {
  .style-guide-typography-grid {
    grid-row-gap: 12px;
  }

  ._2-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .display-heading-2 {
    margin-bottom: 18px;
    font-size: 40px;
    line-height: 44px;
  }

  .display-heading-1 {
    font-size: 44px;
    line-height: 46px;
    letter-spacing: 0em;
  }

  .style-guide-content-grid {
    grid-row-gap: 24px;
  }

  ._3-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .color-scheme-row {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .button.button-large {
    padding: 12px 24px;
  }

  .style-guide-form-grid {
    justify-items: start;
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .style-guide-section {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .utility-page-icon {
    width: 150px;
  }

  ._2-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  ._2-1-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  ._1-2-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .nav-link {
    margin-top: 4px;
    margin-bottom: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
  }

  .sidebar-logo {
    max-height: 24px;
  }

  .nav-divider {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .nav-link-dropdown {
    margin-top: 4px;
    margin-bottom: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
  }

  .nav-link-dropdown-item {
    margin-top: 4px;
    margin-bottom: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
  }

  .dashboard-content {
    padding: 20px;
  }

  .charts-preview {
    height: auto;
  }

  .alert-module {
    font-size: 14px;
    line-height: 22px;
  }

  .table-module.overflow-auto {
    overflow: auto;
  }

  .table-row {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid #e1e6f0;
  }

  .table-row._5-columns {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .table-headers {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .table-headers._5-columns {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .table-avatar-row {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .form-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .password-reset-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .invoice-logo {
    max-height: 24px;
  }

  .invoice-contacts {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .text-right-invoice {
    text-align: left;
  }

  .table-content {
    overflow: auto;
  }

  .crm-name-list {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .alert-module-link {
    margin-left: 16px;
  }

  .presentation-hero-section {
    padding-top: 72px;
  }

  .presentation-title-wrapper {
    margin-bottom: 48px;
  }

  .presentation-section {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .presentation-hero-bg {
    height: 80%;
  }

  .presentation-grid {
    margin-bottom: 64px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .presentation-grid._3-column {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .presentation-category-wrapper {
    margin-bottom: 48px;
  }

  .presentation-cta {
    margin-bottom: 48px;
  }

  .contact-avatar {
    width: 72px;
    height: 72px;
    margin-bottom: 12px;
  }

  .contact-description {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .company-logo {
    width: 48px;
    height: 48px;
    margin-bottom: 12px;
  }

  .company-avatar {
    width: 72px;
    height: 72px;
    margin-bottom: 12px;
  }

  .dashboard-banner {
    padding: 20px;
  }

  .table-content-2 {
    overflow: auto;
  }

  .dashboard-content-2 {
    padding: 20px;
  }

  .table-headers-2 {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .table-headers-2._6-columns {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .alert-module-2 {
    font-size: 14px;
    line-height: 22px;
  }

  .add-prod-table-row {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid #e1e6f0;
  }

  .add-prod-table-row._5-columns {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .add-prod-table-row._5-columns-td {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .info-section.overflow-auto {
    overflow: auto;
  }

  .content {
    overflow: auto;
  }

  .add-prod-table-content {
    overflow: auto;
  }

  .add-prod-table-headers {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .add-prod-table-headers._6-columns {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .table-row-2 {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid #e1e6f0;
  }

  .table-row-2._5-columns {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .timeline-header {
    padding-top: 0px;
  }

  .search-button-2 {
    right: 20px;
  }

  .column-13 {
    display: inline-block;
    padding-left: 55px;
  }

  .border-block__date {
    padding-left: 45px;
  }

  .search-input-radius {
    width: 100%;
  }

  .grid-10 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .dashboard-order-page {
    padding: 20px;
  }
}

@media screen and (max-width: 479px) {
  h3 {
    margin-top: 0px;
    margin-bottom: 18px;
  }

  .large-text {
    font-size: 16px;
    line-height: 24px;
  }

  .account-delete-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .display-heading-2 {
    margin-bottom: 18px;
    font-size: 32px;
    line-height: 36px;
  }

  .display-heading-1 {
    margin-bottom: 18px;
    font-size: 32px;
    line-height: 36px;
  }

  .style-guide-content-grid {
    grid-row-gap: 12px;
  }

  ._4-grid {
    grid-row-gap: 12px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  ._3-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .color-scheme-row {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .style-guide-buttons-grid {
    justify-items: stretch;
    grid-row-gap: 12px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .button.button-large {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .button.button-spacing {
    margin-right: 0px;
    margin-bottom: 16px;
  }

  .form-success {
    padding: 12px;
  }

  .form-error {
    padding: 12px;
  }

  ._2-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .chart-colors-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .in-page-menu {
    margin-bottom: 16px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-bottom-style: none;
  }

  .in-page-menu-link {
    margin-right: 0px;
    margin-bottom: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .table-module.overflow-auto {
    overflow: auto;
  }

  .table-row {
    grid-column-gap: 4px;
    grid-row-gap: 4px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .table-headers {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .table-headers._5-columns {
    grid-column-gap: 4px;
  }

  .content-percentage {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .data-visualisation-storage {
    margin-top: 16px;
    margin-left: 0px;
  }

  .projects-list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .project-list-logo {
    margin-bottom: 14px;
  }

  .members-overview-avatar {
    width: 48px;
    height: 48px;
    margin-right: 8px;
  }

  .account {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .w-tab-link {
    display: block;
  }

  .account-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .form-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .account-delete {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .password-reset-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .invoice-contacts {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .table-headers-invoice {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .table-row-invoice {
    grid-column-gap: 4px;
    grid-row-gap: 4px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .knowledge-base-contact {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .presentation-hero-bg {
    height: 90%;
  }

  .presentation-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .presentation-buttons {
    max-width: 220px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .banner-avatar-wrapper {
    margin-bottom: 20px;
  }

  .dashboard-banner-header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .billing-subscription-title {
    font-size: 20px;
  }

  .table-module-2.overflow-auto {
    overflow: auto;
  }

  .table-headers-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .table-headers-2._6-columns {
    grid-column-gap: 4px;
  }

  .add-prod-table-row {
    grid-column-gap: 4px;
    grid-row-gap: 4px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .in-page-menu-link-2 {
    margin-right: 0px;
    margin-bottom: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .info-section.overflow-auto {
    overflow: auto;
  }

  .table-module-2-copy.overflow-auto {
    overflow: auto;
  }

  .add-prod-table-headers {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .add-prod-table-headers._6-columns {
    grid-column-gap: 4px;
  }

  .table-row-2 {
    grid-column-gap: 4px;
    grid-row-gap: 4px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .heading-28 {
    margin-left: 0px;
    padding-right: 0px;
    font-size: 8px;
  }

  .border-block__date {
    padding-left: 40px;
  }
}

.style-guide-form-grid-2 {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-copy {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-right: 10px;
  padding-left: 10px;
}

.w-col {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
}

.w-col-6 {
  width: 50%;
}

.account-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-top: 24px;
  padding-bottom: 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #e1e6f0;
}

.account-delete-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 48px;
  padding-top: 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #e1e6f0;
}

.button-17 {
  display: inline-block;
  padding: 12px 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  background-color: #1d94d2;
  -webkit-transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
  color: #fff;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}

.button-17.button-small {
  padding: 8px 18px;
  font-size: 14px;
}

.form-caption-2 {
  margin-bottom: 8px;
  color: #627183;
  font-size: 14px;
  line-height: 20px;
}

.button.button-outline {
  border-style: solid;
  border-width: 1px;
  border-color: rgba(20, 20, 20, 0.1);
  background-color: transparent;
  color: #141414;
}

.password-reset-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 14px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.w-input.error {
  border-color: #dc0004;
}
