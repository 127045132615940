.agent-db-window {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 75vh;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 999999;
}
