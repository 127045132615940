.generic-button {
  display: block;
}

.base-button:hover {
  box-shadow: inset 100px 0 100px 100px rgba(0, 0, 0, 0.3);
}

.button-disabled {
  background-color: #b6c5d4 !important;
  color: #e1e6f0 !important;
}

.button-disabled:hover {
  cursor: not-allowed !important;
  box-shadow: none;
}
