.checkbox-select-container {
  margin-bottom: 0;
  width: auto;
  width: 100%;
}

.checkbox-select-container select {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  position: relative;
}

.over-select {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.checkbox-select {
  width: 100%;
  display: block;
}

