.checkbox {
  display: block;
  width: 16px;
  height: 16px;
  margin-bottom: 8px;
  margin-left: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(20, 20, 20, 0.1);
  border-radius: 5px;
  /* background-size: 10px 10px;
  background-repeat: no-repeat;
  background-attachment: scroll; */
  -webkit-transition: background-color 200ms ease, border-color 200ms ease,
    background-position 200ms ease;
  transition: background-color 200ms ease, border-color 200ms ease, background-position 200ms ease;
  cursor: pointer;
}

.dummy-checkbox {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.checkbox.checked {
  border-color: #763ff9;
  background-color: #1d94d2;
  background-image: url("../../assets/images/icon-check.svg");
  background-size: 10px 10px;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 50% 50%;
}

.checkbox-label {
  cursor: pointer;
}
