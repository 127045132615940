.no-show-checkbox {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.switch-input.disabled {
  background-color: #627183 !important;
  background-size: auto !important;
  cursor: not-allowed !important;
}
