/* .transition-alert-enter {
  opacity: 0;
  display: flex;
}

.transition-alert-enter-active {
  opacity: 1;
  transition: all 400ms ease;
  -webkit-transition: all 400ms ease;
} */

.transition-alert-exit {
  opacity: 1;
}

.transition-alert-exit-active {
  opacity: 0;
  transition: all 400ms ease-out;
  -webkit-transition: all 400ms ease-out;
}

.transition-alert-exit-done {
  display: none !important;
}
